import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  OutwardAccepted,
  OutwardMultiple,
  getAllData,
} from "../../../../utils/apis/Outward/Outward";
import Pagination from "../../../common/Pagination";
import { formatDate } from "../../../../utils/common";
import { Submit, EditButton } from "../../../common/Button";
import EditOffCanvance from "./Edit";
import pen from "../../../../Components/Admin/assets/icons/pen.png";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
const Users = ({
  status,
  setStatus,
  handleExportCSV,
  handlePrint,
  handleExportExcel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };


  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);

  const getData = async (search = "") => {
    const res = await getAllData(currentPage, perPage, search);
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);


    if (res?.data?.data) {
      const ids = res.data.data.map(item => item.id);
      setAllChecked(ids);
    } else {
      setAllChecked([]);
    }
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  const Accepted = async (id) => {
    const data = {
      id: id,
      accepted_rejected: 1,
    };

    console.log(id);
    const res = await OutwardAccepted(data);
    if (res.success) {
      getData();
      setStatus(res.success);
    }
  };

  const Multiple = async () => {
    const data = {
      ids: selectAllChecked,
      accepted_rejected: 1,
    };

    const res = await OutwardMultiple(data);
    if (res.success) {
      getData();
      setStatus(res.success);
    }
  };

  const [show1, setShowEdit] = useState(0);
  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  return (
    <div className="search-investor mt-3">
      <div className="investor-main">
        <FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-3" />
        <p className="sub">Users</p>
      </div>
      <div className="inward-payment">
        <div className="nav-link active">
          <div className="dt-buttons btn-group flex-wrap">
            <button
              className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handleExportExcel(data, "Users")}
            >
              <span>Excel</span>
            </button>
            <button
              className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handleExportCSV(data, "Users")}
            >
              <span>CSV</span>
            </button>
            <button
              className="btn btn-secondary buttons-print Print_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handlePrint(data, "Users")}
            >
              <span>Print</span>
            </button>

            <input type="number" className="ps-3" onChange={(e) => setperPage(e.target.value)} placeholder="Enter Per Page Data"></input>
            <button
              className="btn btn-info buttons-print Print_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => Multiple()}
            >
              <span>Submit Multiple</span>
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="simple-form outward-main"
        >


          <div className="form-group">
            <label htmlFor="name">Search: </label>
            <input
              type="text"
              id="name"
              onChange={(e) => {
                getData(e.target.value);
              }}
              placeholder="Search By Service No"
              // {...register("name", { required: "Name is required" })}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            {/* {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
            )} */}
          </div>
        </form>
      </div>
      {/* table started */}
      <div className="table-main">
        <div className="table-responsive mt-2">
          <table className="table table-bordered  table-striped">
            <thead>
              <tr>
                <th className="name">
                  <input
                    type="checkbox"
                    value="selectAll"
                    checked={
                      allChecked.length === selectAllChecked.length
                    }
                    onChange={handleChange}
                    id="selectAll"
                    className="me-1"
                  ></input>
                  Select All
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Payment Date
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Investor Name
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Service No
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  State
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  District
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  A/C No
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  IFSC Code
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>

                <th className="name">
                  Withdrawal Amount
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Payment Type
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>

                {/* <th className="name">
                  Total Amount
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th> */}

                <th className="name">
                  Action
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Withdrawal Date
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
              </tr>
              {/*  onClick={() => setModalShow(true)} <ScreenShot show={modalShow} onHide={() => setModalShow(false)} /> */}
            </thead>
            <tbody>
              {data?.data?.map((data, index) => (
                <tr className="odd" key={index}>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={data?.id}
                      name="perselected"
                      checked={selectAllChecked.includes(data?.id)}
                      onChange={handleChange}
                      id="flexCheckDefault"
                    ></input>
                  </td>
                  <td>{formatDate(data?.createdAt)}</td>
                  <td>
                    {data?.user?.f_name} {data?.user?.l_name}
                  </td>
                  <td>{data?.user?.contact_no}</td>
                  <td>{data?.user?.users_detail?.state?.name}</td>
                  <td>{data?.user?.users_detail?.city?.name}</td>
                  <td>{data?.ac_no}</td>
                  <td>{data?.ifsc_code}</td>
                  <td>{data?.amount}</td>
                  <td>{data?.withdrawn_type}</td>
                  {/* <td>{data?.user?.total}</td> */}
                  <td>
                    {" "}
                    <Submit handleSubmit={() => Accepted(data?.id)} />


                  </td>
                  <td>
                    {" "}
                    <Button
                      onClick={() => handleShow1(data?.id)}
                      type="button"
                      className="btn btn-primary me-1"
                    >
                      <img src={pen} className="pen" alt="" />
                    </Button>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* table ended */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        TotalPages={TotalPages}
        TotalEntries={TotalEntries}
        perPage={perPage}
      />
      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}
    </div>

  );
};

export default Users;
