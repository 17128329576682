import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    getValues,
    watch,
  } = useForm();
  const id = props.show;

  const [formData, setFormData] = useState({
    name: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = Validation.FormValidation({
  //     classname: ".occupationclass",
  //   });

  //   if (validationErrors) {
  //     try {
  //       const response = await postData(`/payment/inward-payment/${id}`, formData);

  //       if (response.success) {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       } else {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  const onSubmit = async (data) => {
    try {
      let response;
      response = await postData(`/payment/outward-payment/data/${id}`, data);
     

      // console.log(response);

      if (response.success) {
        setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          setShowModal({ code: 0, message: "" });
          props.handleClose();
        }, 1000);
      } else {
        setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          setShowModal({ code: 0, message: "" });
          props.handleClose();
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // const GetEditData = async () => {
  //   const response = await getData(`/payment/outward-payment/${id}`);
  //   setFormData(response?.data);
  //   reset(response?.data);
  // };
  const GetEditData = async () => {
    const response = await getData(`/payment/outward-payment/${id}`);
    if (response?.data) {
      const data = response.data;
      
      // Ensure withdraw_date_time is in the correct format
      if (data.withdraw_date_time) {
        // Convert from UTC to local time
        const date = new Date(data.withdraw_date_time);
        const localDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        
        // Format as YYYY-MM-DDTHH:MM
        data.withdraw_date_time = localDateTime.toISOString().slice(0, 16);
      }
  
      // Set form data and reset form with the updated data
      setFormData(data);
      reset(data);
    }
  };
  

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const acceptedRejectedValue = watch("accepted_rejected");
 console.log(getValues(),"getValues() getValues()");
 
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Withdrawal Date Time </Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            // onSubmit={handleSubmit}
                            onSubmit={handleSubmit(onSubmit)}
                            role="form"
                            className="occupationclass"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                         Withdrawal Date Time
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                           
                                               <Form.Control
                                                 type="datetime-local"
                                               
                                                  name="withdraw_date_time"
                                                  {...register("withdraw_date_time", {
                                                    required:
                                                      "Corporate Address is required",
                                                  })}
                                                  className={`form-control ${errors.withdraw_date_time
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Corporate Address"
                                                  id="inputEmail3"
                                                />{" "}
                                              <span className="error-message"></span>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                {/* <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Uploaded Screenshot
                                        </Form.Label>
                                        <Col sm={6}>
                                          <div className="image-boxes-main">
                                            <img
                                              className="image-boxes"
                                              width={"400px"}
                                              height={"400px"}
                                              src={
                                                IMG_URL +
                                                getValues("screenshot")
                                              }
                                              alt="Uploaded Screenshot"
                                            ></img>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Accepted OR Rejected
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Check
                                                type="radio"
                                                label="Accepted"
                                                name="accepted_rejected"
                                                value="1"
                                                {...register(
                                                  "accepted_rejected",
                                                  {
                                                    required:
                                                      "Accepted or Rejected is required",
                                                  }
                                                )}
                                                checked={
                                                  acceptedRejectedValue === "1"
                                                }
                                                onChange={(e) =>
                                                  setValue(
                                                    "accepted_rejected",
                                                    e.target.value
                                                  )
                                                }
                                                className="input-mandatory me-2"
                                                id="accepted"
                                              />
                                              <Form.Check
                                                type="radio"
                                                label="Rejected"
                                                name="accepted_rejected"
                                                value="0"
                                                {...register(
                                                  "accepted_rejected",
                                                  {
                                                    required:
                                                      "Accepted or Rejected is required",
                                                  }
                                                )}
                                                checked={
                                                  acceptedRejectedValue === "0"
                                                }
                                                onChange={(e) =>
                                                  setValue(
                                                    "accepted_rejected",
                                                    e.target.value
                                                  )
                                                }
                                                className="input-mandatory"
                                                id="rejected"
                                              />
                                            </InputGroup>
                                            {errors.accepted_rejected && (
                                              <span
                                                className="error-message"
                                                style={errorStyle}
                                              >
                                                {
                                                  errors.accepted_rejected
                                                    .message
                                                }
                                              </span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                {getValues("accepted_rejected") == "1" && (
                                  <Row>
                                    <Col md={12}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Amount
                                          </Form.Label>
                                          <Col sm={6}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="number"
                                                  {...register("amount", {
                                                    required:
                                                      "Amount  is required",
                                                  })}
                                                  name="amount"
                                                  className="input-mandatory"
                                                  id="inputEmail3"
                                                />
                                              </InputGroup>{" "}
                                              {errors.amount && (
                                                <span
                                                  className="error-message"
                                                  style={errorStyle}
                                                >
                                                  {errors.amount.message}
                                                </span>
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                )}

                                {getValues("accepted_rejected") == "0" && (
                                  <Row>
                                    <Col md={12}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Reason
                                          </Form.Label>
                                          <Col sm={6}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  {...register("reason", {
                                                    required:
                                                      "Reason is required",
                                                  })}
                                                  className="input-mandatory"
                                                  id="inputEmail3"
                                                />
                                              </InputGroup>{" "}
                                              {errors.reason && (
                                                <span
                                                  className="error-message"
                                                  style={errorStyle}
                                                >
                                                  {errors.reason.message}
                                                </span>
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                )} */}

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      // handleSubmit={handleSubmit}
                                      handleSubmit={() =>
                                        handleSubmit(onSubmit)
                                      }
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
