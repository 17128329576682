// Utility functions for CSV and Excel export

// Function to export data as CSV
const exportCSV = (data, header, fileName) => {
  const contentHeader = header
    ? `${header.map((e) => e.name).join(";")}\n`
    : "";
  const content = `${contentHeader}${data.map((e) => concatCSV(e)).join("\n")}`;
  return {
    content: content,
    type: "text/csv",
    name: `${fileName || document.title}.csv`,
  };
};

// Function to concatenate data for CSV
const concatCSV = (row) => {
  return row.map((field) => `"${field}"`).join(";");
};

// Function to export data as Excel
const exportExcel = (data, header, fileName) => {
  const contentHeader = header
    ? `<thead><tr><td>${header
        .map((e) => e.name)
        .join("</td><td>")}</td><tr></thead>`
    : "";
  const contentBody = data.map((e) => concatExcel(e));
  const content = `<table>${contentHeader}<tbody>${contentBody.join(
    ""
  )}</tbody></table>`;
  return {
    content: content,
    type: "application/vnd.ms-excel",
    name: `${fileName || document.title}.xls`,
  };
};

// Function to concatenate data for Excel
const concatExcel = (row) => {
  return `<tr>${row.map((field) => `<td>${field}</td>`).join("")}</tr>`;
};

// Function to prepare content for printing
const exportPrint = (data, header) => {
  const { content } = exportExcel(data, header);
  const style = `
      body, table { 
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; 
        font-size: 12px 
      }
      table {
        width: 100%;
      }
      thead {
        font-weight: bold;
      }
    `;
  return `<style>${style}</style>${content}`;
};

// Export methods as an object
const ExportMethod = {
  csv: exportCSV,
  excel: exportExcel,
  print: exportPrint,
};

export default ExportMethod;
