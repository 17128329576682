import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (page, per_page, search,name) => {
  try {
    let url = `/payment/inward-payment?dum=a`;

    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    if (name) {
      url += `&name=${name}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const getAllReportsData = async (page, per_page, search) => {
  try {
    let url = `/payment/inward-payment/reports?`;

    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const InwardAccepted = async (data) => {
  try {
    return await postData(`/payment/inward-payment/accepted`, data);
  } catch (error) {
    console.error(error);
  }
};

export const InwardManual = async (data) => {
  try {
    return await postData(`/payment/inward-payment/manual`, data);
  } catch (error) {
    console.error(error);
  }
};

export const InwardEdit = async (data) => {
  try {
    return await postData(`/payment/inward-payment/edit`, data);
  } catch (error) {
    console.error(error);
  }
};


export const Delete = async (id) => {
  try {
    return await deleteData(`/payment/inward-payment/${id}`);
  } catch (error) {
    console.error(error);
  }
};