import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-js-loader";

import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  Create,
  getAllData,
} from "../../../../utils/apis/Notification/Notification";
import { Select2Data, formatDate } from "../../../../utils/common";
import Pagination from "../../../common/Pagination";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ExportMethod from "../../../../utils/apis/ExportMethod";
import {
  City,
  Occupation,
  State,
} from "../../../../utils/apis/masters/masters";

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  // hok form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const { IMG_URL } = useContext(Context);

  const [activeStatus, setActiveStatus] = useState("All");
  const handleRadioChange = (status) => {
    setActiveStatus(status);
    // You can perform additional actions based on the selected status here
  };

  console.log(activeStatus,'lll');

  const [shimmerLoader, setShimmerLoader] = useState(false);

  const onSubmit = async (data) => {
    
    setShimmerLoader(true);
    const finalData = new FormData();
    finalData.append("image", data?.image?.[0]);
    finalData.append("name", data?.name);
    finalData.append("description", data?.description);
    if(data?.state_id?.value)
    finalData.append("state_id", data?.state_id?.value);
    if(data?.city_id?.value)
    finalData.append("city_id", data?.city_id?.value);
    if(data?.occupation_id?.value)
    finalData.append("occupation_id", data?.occupation_id?.value);
    finalData.append("active", activeStatus);
    console.log(finalData, "lllllll");
    const res = await Create(finalData);
    if (res.success) {
      // reset();
      setShimmerLoader(false);
      getData();
      reset();
      setValue('image',"");
      setValue('name',"");
      setValue('description',"");
      setValue('state_id',"");
      setValue('city_id',"");
      setValue('occupation_id',"");
      setActiveStatus("All");
    }else{
      
      setShimmerLoader(false);
    }
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);

  const getData = async (search = "") => {
    const res = await getAllData(currentPage, perPage, search);
    setData(res?.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  const Clicked = async () => {
    console.log("Clicked");
  };
  const columns = [
    {
      name: "Date",
      selector: "createdAt",
      sortable: true,
      cell: (d) => formatDate(d.createdAt),
    },
    {
      name: "Notification Message",
      selector: "name",
      sortable: true,
      // cell: (row) => <button onClick={() => Clicked()}>{row.name}</button>,
    },
  ];

  const tableData = {
    columns,
    data: data?.data,
  };

  // Exports
  const handleExportCSV = () => {
    const header = [{ name: "Date" }, { name: "Notification Message" }];
    const exportData = data?.data?.map((d) => [
      formatDate(d.createdAt),
      d.name,
    ]);
    const { content, type, name } = ExportMethod.csv(
      exportData,
      header,
      "Notification Message"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = () => {
    const header = [{ name: "Date" }, { name: "Notification Message" }];
    const exportData = data?.data?.map((d) => [
      formatDate(d.createdAt),
      d.name,
    ]);
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "Notification Message"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handlePrint = () => {
    const header = [{ name: "Date" }, { name: "Notification Message" }];
    const exportData = data?.data?.map((d) => [
      formatDate(d.createdAt),
      d.name,
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close("", "");
    printWindow.print();
  };

  const [state, setState] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [city, setCity] = useState([]);
  const getMastersData = async () => {
    {
      const res = await State();
      await setState(await Select2Data(res?.data, "state_id"));
    }
    {
      const res = await Occupation();
      await setOccupation(await Select2Data(res?.data, "occupation_id"));
    }
  };

  const handleStateSelect = async (id) => {
    setValue("city_id", "");
    const res = await City(id);
    if (res?.success) {
      await setCity(await Select2Data(res?.data, "city_id"));
    }
  };

  useEffect(() => {
    getMastersData();
  }, []);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Notification"} link={"/notification"} />
        <div className="search-investor mt-3">
          <div className="notification">
            <Row>
              <Col xxl={12}>
                <div className="form-group">
                  <label htmlFor="name me-4">Image: </label>
                  <input
                    type="file"
                    id="name"
                    placeholder="Select Image"
                    {...register("image")}
                    className={`form-control ${errors.image ? "is-invalid" : ""
                      }`}
                    accept="image/*"
                  />
                  {/* {errors.name && (
                          <div className="invalid-feedback">
                            {errors.name.message}
                          </div>
                        )} */}
                  <label htmlFor="name me-4">Title: </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter Notification Title"
                    {...register("name", {
                      required: "Title is required",
                    })}
                    className={`form-control ${errors.name ? "is-invalid" : ""
                      }`}
                  />

                  <label htmlFor="name me-4">Message: </label>
                  <input
                    type="text"
                    id="description"
                    placeholder="Enter Notification Message"
                    {...register("description", {
                      required: "Message is required",
                    })}
                    className={`form-control ${errors.description ? "is-invalid" : ""
                      }`}
                  />
                  {/* {errors.name && (
                          <div className="invalid-feedback">
                            {errors.name.message}
                          </div>
                        )} */}
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4} className="mt-2">
                <p className="sub">State</p>
                <Controller
                  name="state_id"
                  {...register("state_id", {})}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.state_id ? "red" : baseStyles,
                        }),
                      }}
                      classNamePrefix="custom-select-class"
                      placeholder="State"
                      {...field}
                      options={state}
                      onChange={(selectedOption) => {
                        setValue("state_id", null);
                        field.onChange(selectedOption);
                        handleStateSelect(selectedOption.value);
                      }}
                    />
                  )}
                />
                {/* <Select
                  id="inputState"
                  options={state}
                  placeholder="State"
                  classNamePrefix="custom-select-class"
                /> */}
              </Col>
              <Col xxl={3} xl={3} lg={4} className="mt-2">
                <p className="sub">District</p>
                <Controller
                  name="city_id"
                  {...register("city_id", {})}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.city_id ? "red" : baseStyles,
                        }),
                      }}
                      classNamePrefix="custom-select-class"
                      placeholder="City"
                      {...field}
                      options={city}
                      onChange={(selectedOption) => {
                        setValue("city_id", null);
                        field.onChange(selectedOption);
                        // handleStateSelect(selectedOption.value);
                      }}
                    />
                  )}
                />
                {/* <Select
                  id="inputState"
                  options={locationOptions}
                  placeholder="State"
                  classNamePrefix="custom-select-class"
                /> */}
              </Col>
              <Col xxl={3} xl={3} lg={4} className="mt-2">
                <p className="sub">Occupation</p>
                <Controller
                  name="occupation_id"
                  {...register("occupation_id", {})}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.occupation_id
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      classNamePrefix="custom-select-class"
                      placeholder="Occupation"
                      {...field}
                      options={occupation}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        // handlePincodeSelect(selectedOption.value);
                      }}
                    />
                  )}
                />
                {/* <Select
                  id="inputState"
                  options={locationOptions}
                  placeholder="State"
                  classNamePrefix="custom-select-class"
                /> */}
              </Col>
              <Col xxl={3} xl={3} lg={12} className=" my-auto">
              <p className="sub"></p>
              <div className="d-flex flex-wrap align-items-center">
                <label className="sub  d-flex">
               
                  <input
                    className="form-radio-input "
                    type="radio"
                    checked={activeStatus === "All"}
                    onChange={() => handleRadioChange("All")}
                  />
                 <span className="ms-2 mt-2">All Users</span> 
                </label>

                <label className="sub d-flex">
                  <input
                    className="form-radio-input"
                    type="radio"
                    checked={activeStatus === "Active"}
                    onChange={() => handleRadioChange("Active")}
                  />
                  
                  <span className="ms-2 mt-2">Active Users</span> 
                </label>

                <label className="sub d-flex">
                  <input
                    className="form-radio-input"
                    type="radio"
                    checked={activeStatus === "Inactive"}
                    onChange={() => handleRadioChange("Inactive")}
                  />
                  
                  <span className="ms-2 mt-2">Inactive Users</span> 
                </label>
                {/* <Select
                  id="inputState"
                  options={locationOptions}
                  placeholder="State"
                  classNamePrefix="custom-select-class"
                /> */}
                </div>
              </Col>
              <Col xxl={6} className="mt-3">

              {shimmerLoader ? (
                     <Loader type="bubble-top" bgColor={"#000"} color={"#000"} size={50} />
                    ) : (
                <Button
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                  className="submit"
                >
                  Submit
                </Button>
                    )}
              </Col>
            </Row>
          </div>
        </div>
        <div className="search-investor mt-4">
          <div className="investor-main">
            <FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-3" />
            <p className="sub">Notification History</p>
          </div>

          <div className="inward-payment">
            <div className="nav-link active">
              <div className="dt-buttons btn-group flex-wrap">
                <button
                  className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handleExportExcel}
                >
                  <span>Excel</span>
                </button>
                <button
                  className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handleExportCSV}
                >
                  <span>CSV</span>
                </button>
                <button
                  className="btn btn-secondary buttons-print Print_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handlePrint}
                >
                  <span>Print</span>
                </button>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="simple-form outward-main"
            >
              <div className="form-group">
                <label htmlFor="name">Search: </label>
                <input
                  type="text"
                  id="name"
                  onChange={(e) => {
                    getData(e.target.value);
                  }}
                  placeholder="Search By Notification Message"
                  // {...register("name", { required: "Name is required" })}
                  className="form-control"
                // className={`form-control ${errors.name ? "is-invalid" : ""}`}
                />
              </div>
            </form>
          </div>

          {/* table started */}
          <div className="table-main">
            <div className="table-responsive mt-2">
              <table className="table table-bordered  table-striped">
                <thead>
                  <tr>
                    <th className="name">
                      Date
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Image
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Notification Title
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Notification Message
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      State
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      City
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Occupation
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Users
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((data, index) => (
                    <tr className="odd">
                      <td>{formatDate(data?.createdAt)}</td>
                      <td>
                        <img src={IMG_URL + data?.image} width={60} />
                      </td>
                      <td>{data?.name}</td>
                      <td>{data?.description}</td>
                      <td>{data?.state?.name}</td>
                      <td>{data?.city?.name}</td>
                      <td>{data?.occupation?.name}</td>
                      <td>{data?.active_status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* <DataTableExtensions {...tableData}>
            <DataTable
              noHeader
              defaultSortAsc={true}
              // pagination
              highlightOnHover
            />
          </DataTableExtensions> */}
          {/* table ended */}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            TotalPages={TotalPages}
            TotalEntries={TotalEntries}
            perPage={perPage}
          />
        </div>
      </div>

      {/* {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}
      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      /> */}
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
