import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Controller, useForm } from "react-hook-form";

import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBook, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-js-loader";
import { Button, Col, Row } from "react-bootstrap";
import Datepicker from "../../../common/Datepicker";
import { Link } from "react-router-dom";
import { Submit } from "../../../common/Button";
import {
  getAllData,
  Create,
  Delete,
} from "../../../../utils/apis/Operations/AppOperations";
import Select from "react-select";
import { postData } from "../../../../utils/api";
import Pagination from "../../../common/Pagination";
import { formatDate, formatDateTime } from "../../../../utils/common";
import ExportMethod from "../../../../utils/apis/ExportMethod";
import DateTimePickerComponent from "../../../common/DateTimePicker";

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  const {
    reset,
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      type: { value: "Buy", name: "type", label: "Buy" },
      b_segment_name: "",
      b_price: "",
      s_segment_name: "",
      s_price: "",
      trade_qty: "",
      total: "",
      total_buy: "",
      total_sell: "",
      profit_loss: "",
      b_date: "",
      s_date: "",
    },
  });

  const onSubmit = async (data) => {
    // console.log(data);
    setShimmerLoader(true);
    const finalData = new FormData();

    if (data?.icon) {
      finalData.append('icon', data?.icon?.[0]);
    }
    finalData.append('b_segment_name', data?.b_segment_name);
    finalData.append('b_price', data?.b_price);
    finalData.append('s_segment_name', data?.s_segment_name);
    finalData.append('s_price', data?.s_price);
    finalData.append('trade_qty', data?.trade_qty);
    finalData.append('total', data?.total);
    finalData.append('total_buy', data?.total_buy);
    finalData.append('total_sell', data?.total_sell);
    finalData.append('profit_loss', data?.profit_loss);
    finalData.append('b_date', data?.b_date);
    finalData.append('s_date', data?.s_date);

    const res = await Create(finalData);
    reset();
    setValue('icon', "");
    setImagePreview("");
    if (res?.success) {
      setShimmerLoader(false);
      getData();
    }else{
      setShimmerLoader(false);
    }
    
  };

  const SegamentDelete = async (id) => {
    const res = await Delete(id);
    getData();
  };

  const [type, setType] = useState([
    {
      value: "Buy",
      name: "type",
      label: "Buy",
    },
    {
      value: "Sell",
      name: "type",
      label: "Sell",
    },
  ]);

  const [data, setData] = useState([]);
  const [shimmerLoader, setShimmerLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);
  const { IMG_URL } = useContext(Context);

  const getData = async (search = "") => {
    const res = await getAllData(currentPage, perPage, search);
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  const Calculate = async () => {
    const data = getValues();
    // console.log(data);
    if (!data.trade_qty) {
      setError(`trade_qty`, {
        type: "manual",
        message: "Enter trade_qty",
      });
    } else if (!data.b_price) {
      setError(`b_price`, {
        type: "manual",
        message: "Enter b_price",
      });
    } else if (!data.s_price) {
      setError(`s_price`, {
        type: "manual",
        message: "Enter s_price",
      });
    } else {
      clearErrors();
      // console.log(data);
      const total_buy = data.b_price * data.trade_qty;
      // console.log("total_buy =" + total_buy);
      const total_sell = data.s_price * data.trade_qty;
      // console.log("total_sell =" + total_sell);
      const profit_loss = total_sell - total_buy;
      // console.log("profit_loss =" + profit_loss);
      setValue("total_buy", total_buy);
      setValue("total_sell", total_sell);
      setValue("total", profit_loss);
    }
  };

  // console.log(errors);

  //

  const handleExportCSV = () => {
    const header = [
      { name: "Segment ID" },
      { name: "Buy Segment Name" },
      { name: "Buy Price" },
      { name: "Buy Time" },
      { name: "Sell Segment Name" },
      { name: "Sell Price" },
      { name: "Sell Time" },
      { name: "Qty" },
      { name: "P&L" },
      { name: "Date" },
    ];
    const exportData = data?.data?.map((data) => [
      data?.id,
      data?.b_segment_name,
      data?.b_price,
      data?.b_date,
      data?.s_segment_name,
      data?.s_price,
      data?.s_date,
      data?.trade_qty,
      data?.total,
      formatDate(data.createdAt),
    ]);
    const { content, type, name } = ExportMethod.csv(
      exportData,
      header,
      "Operation"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = () => {
    const header = [
      { name: "Segment ID" },
      { name: "Buy Segment Name" },
      { name: "Buy Price" },
      { name: "Buy Time" },
      { name: "Sell Segment Name" },
      { name: "Sell Price" },
      { name: "Sell Time" },
      { name: "Qty" },
      { name: "P&L" },
      { name: "Date" },
    ];
    const exportData = data?.data?.map((data) => [
      data?.id,
      data?.b_segment_name,
      data?.b_price,
      data?.b_date,
      data?.s_segment_name,
      data?.s_price,
      data?.s_date,
      data?.trade_qty,
      data?.total,
      formatDate(data.createdAt),
    ]);
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "Operations"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handlePrint = () => {
    const header = [
      { name: "Segment ID" },
      { name: "Buy Segment Name" },
      { name: "Buy Price" },
      { name: "Buy Time" },
      { name: "Sell Segment Name" },
      { name: "Sell Price" },
      { name: "Sell Time" },
      { name: "Qty" },
      { name: "P&L" },
      { name: "Date" },
    ];
    const exportData = data?.data?.map((data) => [
      data?.id,
      data?.b_segment_name,
      data?.b_price,
      data?.b_date,
      data?.s_segment_name,
      data?.s_price,
      data?.s_date,
      data?.trade_qty,
      data?.total,
      formatDate(data.createdAt),
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  };

  const [imagePreview, setImagePreview] = useState(null); // State to store image preview

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <div className="investor">
          <Header title={"App Operations"} link={"/payment/inward-payment"} />

          <div className="search-investor">
            <ul>
              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    Icon
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="file"
                      accept="image/*"
                      id="icon"
                      placeholder="Select Icon"
                      {...register("icon", {
                        // required: "Icon is required",
                      })}
                      className={`form-control ${errors.icon ? "is-invalid" : ""
                        }`}
                      onChange={handleImageChange}
                    />
                    {/* {errors.name && (
                      <div className="invalid-feedback">
                        {errors.name.message}
                      </div>
                    )} */}
                  </div>
                  {imagePreview && (
                    <div className=" me-2">
                      <img src={imagePreview} width={50} />
                    </div>
                  )}

                </div>
              </li>
              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    Add Trade <span className={"buy"}>Buy</span>
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="text"
                      id="name"
                      placeholder="Segment Name"
                      {...register("b_segment_name", {
                        required: "Segment name is required",
                      })}
                      className={`form-control ${errors.b_segment_name ? "is-invalid" : ""
                        }`}
                    />
                    {/* {errors.name && (
                      <div className="invalid-feedback">
                        {errors.name.message}
                      </div>
                    )} */}
                  </div>
                  <div className="form-group me-2">
                    <input
                      type="number"
                      id="name"
                      placeholder="Price"
                      {...register("b_price", {
                        required: "Price is required",
                      })}
                      className={`form-control ${errors.b_price ? "is-invalid" : ""
                        }`}
                    />
                  </div>
                  <div className="form-group me-2">
                    <input
                      type="text"
                      id="name"
                      placeholder="Buying Time hh:mm:ss"
                      {...register("b_date", {
                        required: "Buying Time is required",
                      })}
                      className={`form-control ${errors.b_date ? "is-invalid" : ""
                        }`}
                    />
                  </div>

                  {/* <DateTimePickerComponent
                    setValue={setValue}
                    name={"b_date"}
                    errors={errors}
                    register={register}
                  /> */}
                </div>
              </li>

              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    Add Trade <span className={"sell"}>Sell</span>
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="text"
                      id="name"
                      placeholder="Segment Name"
                      {...register("s_segment_name", {
                        required: "Segment name is required",
                      })}
                      className={`form-control ${errors.s_segment_name ? "is-invalid" : ""
                        }`}
                    />
                  </div>
                  <div className="form-group me-2">
                    <input
                      type="number"
                      id="name"
                      placeholder="Price"
                      {...register("s_price", {
                        required: "Price is required",
                      })}
                      className={`form-control ${errors.s_price ? "is-invalid" : ""
                        }`}
                    />
                  </div>

                  <div className="form-group me-2">
                    <input
                      type="text"
                      id="name"
                      placeholder="Selling Time hh:mm:ss"
                      {...register("s_date", {
                        required: "Selling Time is required",
                      })}
                      className={`form-control ${errors.s_date ? "is-invalid" : ""
                        }`}
                    />
                  </div>

                  {/* <DateTimePickerComponent
                    setValue={setValue}
                    name={"s_date"}
                    errors={errors}
                    register={register}
                  /> */}
                </div>
              </li>

              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    Add Trade <span className="textcolor-holder">Qty</span>
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="number"
                      placeholder="Trade Qty"
                      {...register("trade_qty", {
                        required: "Trade Qty is required",
                      })}
                      className={`form-control ${errors.trade_qty ? "is-invalid" : ""
                        }`}
                    />
                  </div>
                </div>
              </li>
              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    P&L <span className="textcolor-holder">Updation</span>
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="number"
                      placeholder="P&L amount"
                      disabled
                      {...register("total", {
                        required: "P&L amount is required",
                      })}
                      className={`form-control ${errors.total ? "is-invalid" : ""
                        }`}
                    />
                  </div>
                </div>
              </li>

              <li className="list-opera list-Submit-btn ">
                <div className="operation">
                  <p className="op"></p>

                  <div className="Submit  Submit-btn-holder">
                    <button className="btn btn-warning" onClick={Calculate}>
                      Calculate
                    </button>
                  </div>

                  {/* <div className="Submit  Submit-btn-holder">
                    <button className="btn btn-warning" onClick={() => reset()}>
                      Reset
                    </button>
                  </div> */}
                  <div className="Submit  Submit-btn-holder">
                    {shimmerLoader ? (
                     <Loader type="bubble-top" bgColor={"#000"} color={"#000"} size={50} />
                    ) : (
                      <Submit name="hjgh" handleSubmit={handleSubmit(onSubmit)} />
                    )}



                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="search-investor mt-4">
            <div className="investor-main">
              <FontAwesomeIcon className="me-3" icon={faBook} />
              <p className="sub">Segment History</p>
            </div>
            <div className="inward-payment">
              <div className="nav-link active">
                <div className="dt-buttons btn-group flex-wrap">
                  <button
                    className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportExcel}
                  >
                    <span>Excel</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportCSV}
                  >
                    <span>CSV</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-print Print_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handlePrint}
                  >
                    <span>Print</span>
                  </button>
                  <input type="number" className="ps-3" onChange={(e) => setperPage(e.target.value)} placeholder="Enter Per Page Data"></input>
                </div>
              </div>
              <div className="simple-form outward-main">
                <div className="form-group">
                  <label htmlFor="name">Search: </label>
                  <input
                    type="text"
                    id="name"
                    onChange={(e) => {
                      getData(e.target.value);
                    }}
                    className={`form-control ${errors.name ? "is-invalid" : ""
                      }`}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">
                      {errors.name.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* table started */}
            <div className="table-main">
              <div className="table-responsive mt-2">
                <table className="table table-bordered  table-striped">
                  <thead>
                    <tr>
                      <th className="name">
                        Segment ID
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Icon
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Buy Segment Name
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Buy Price
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Buy Date
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Sell Segment Name
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Sell Price
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Sell Date
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Qty.
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        P&L
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Delete Segment
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>

                      <th className="name">
                        Date
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((data, index) => (
                      <tr className="odd">
                        <td>{data?.id}</td>
                        <td>
                          {data?.icon !== null ? (
                            <img src={IMG_URL + data?.icon} width={20} />
                          ) : (
                            <div width={20} className="btn btn-info" style={{ borderRadius: 50 }}>
                              <span>{data?.b_segment_name ? data?.b_segment_name.charAt(0).toUpperCase() : ''}</span>
                            </div>
                          )}


                        </td>
                        <td>{data?.b_segment_name}</td>
                        <td>{data?.b_price}</td>
                        <td>{data?.b_date}</td>
                        <td>{data?.s_segment_name}</td>
                        <td>{data?.s_price}</td>
                        <td>{data?.s_date}</td>
                        <td>{data?.trade_qty}</td>
                        <td>{data?.total}</td>
                        <td>
                          <button
                            type="button"
                            onClick={() => SegamentDelete(data.id)}
                            className="btn btn-danger"
                          >
                            Delete
                          </button>{" "}
                        </td>
                        <td>{formatDate(data?.createdAt)}</td>

                        {/* <td
                        valign="top"
                        colspan="10"
                        className="dataTables_empty text-center"
                      >
                        No data available in table
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* table ended */}

            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              TotalPages={TotalPages}
              TotalEntries={TotalEntries}
              perPage={perPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
