export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);

  return a;
};

export function formatDate(dateString) {
  const date = new Date(dateString);

  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  // Get formatted date string in the form "Sep 2, 2024, 7:39 AM"
  const formattedDate = date.toLocaleString('en-US', options);

  // Split the formatted date into its parts
  const [month, day, year] = formattedDate.match(/(\w+) (\d+), (\d+)/).slice(1);
  const time = formattedDate.match(/(\d+:\d+\s[APM]+)/)[0];

  // Rearrange to "6 Aug 2024, 7:39 AM"
  return `${day} ${month} ${year}, ${time}`;
}




// export function formatDate(dateString) {
//   const date = new Date(dateString);
//   return `${date?.toISOString()?.split("T")?.[0]}  ${date?.toTimeString()?.split(" ")[0]}`;
// }
export function formatDateTime(dateString) {
  const date = new Date(dateString);

  // Extract individual components
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // getUTCMonth returns month from 0-11
  const day = date.getUTCDate();
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Determine AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Add leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  // Format components as needed
  const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

  return formattedDate;
}

export function getPaginationMessage(TotalEntries, per_page, currentPage) {
  // Calculate the starting and ending entries for the current page
  let startEntry = (currentPage - 1) * per_page + 1;
  let endEntry = currentPage * per_page;

  // Ensure the end entry does not exceed the Total number of entries
  if (endEntry > TotalEntries) {
    endEntry = TotalEntries;
  }

  return `Showing ${startEntry} to ${endEntry} of ${TotalEntries} entries`;
}

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};
