import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { getAllReportsData } from "../../../../utils/apis/Outward/Outward";
import { formatDate } from "../../../../utils/common";
import Pagination from "../../../common/Pagination";
import ExportMethod from "../../../../utils/apis/ExportMethod";

const Reports = ({
  status,
  setStatus,
  handleExportCSV,
  handlePrint,
  handleExportExcel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);

  const getData = async (search = "") => {
    const res = await getAllReportsData(currentPage, perPage, search);
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage, status]);

  return (
    <div className="search-investor mt-4">
      <p className="sub">Report</p>
      <div className="inward-payment">
        <div className="nav-link active">
          <div className="dt-buttons btn-group flex-wrap">
            <button
              className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handleExportExcel(data, "Report")}
            >
              <span>Excel</span>
            </button>
            <button
              className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handleExportCSV(data, "Report")}
            >
              <span>CSV</span>
            </button>
            <button
              className="btn btn-secondary buttons-print Print_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handlePrint(data, "Report")}
            >
              <span>Print</span>
            </button>

            
            
            <input type="number" className="ps-3" onChange={(e) => setperPage(e.target.value)} placeholder="Enter Per Page Data"></input>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="simple-form outward-main"
        >
          <div className="form-group">
            <label htmlFor="name">Search: </label>
            <input
              type="text"
              id="name"
              onChange={(e) => {
                getData(e.target.value);
              }}
              placeholder="Search By Service No"
              // {...register("name", { required: "Name is required" })}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            {/* {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
            )} */}
          </div>
        </form>
      </div>
      {/* table started */}
      <div className="table-main">
        <div className="table-responsive mt-2">
          <table className="table table-bordered  table-striped">
            <thead>
              <tr>
                <th className="name">
                  Payment Date
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Investor Name
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Service No
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  State
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  District
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                
                <th className="name">
                  A/C No
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  IFSC Code
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>

                <th className="name">
                  Withdrawal Amount
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Payment Type
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>

                {/* <th className="name">
                  Total Amount
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th> */}
                <th className="name">
                  Action
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((data, index) => (
                <tr className="odd" key={index}>
                  <td>{formatDate(data?.createdAt)}</td>
                  <td>
                    {data?.user?.f_name} {data?.user?.l_name}
                  </td>
                  <td>{data?.user?.contact_no}</td>
                  <td>{data?.user?.users_detail?.state?.name}</td>
                  <td>{data?.user?.users_detail?.city?.name}</td>
                  <td>{data?.ac_no}</td>
                  <td>{data?.ifsc_code}</td>
                  <td>{data?.amount}</td>
                  <td>{data?.withdrawn_type}</td>
                  <td>{data?.accepted_rejected ? "Accepted" : "Rejected"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* table ended */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        TotalPages={TotalPages}
        TotalEntries={TotalEntries}
        perPage={perPage}
      />
    </div>
  );
};

export default Reports;
