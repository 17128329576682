import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";

import "./App.css";

import Login from "./Components/Admin/Login/Login";
import Sidebar from "./Components/Admin/Sidebar/Sidebar";
import Header from "./Components/Admin/Header/Header";
import Order from "./Components/Admin/DashBoard/Order/Order";
import Country from "./Components/Admin/Masters/Country/Tables";
import State from "./Components/Admin/Masters/State/Tables";
import City from "./Components/Admin/Masters/City/Tables";
import Pincode from "./Components/Admin/Masters/Pincode/Tables";
import Occupation from "./Components/Admin/Masters/Occupation/Tables";
import FAQ from "./Components/Admin/Masters/Faq/Tables";
import AppSetup from "./Components/Admin/Masters/App_setup/Tables";
import Calendars from "./Components/Admin/Masters/Calendar/Tables";
import DashboardMain from "./Components/Admin/DashboardMain/DashboardMain/Tables";
import Investor from "./Components/Admin/Investor/Investor/Tables";
import Investorledger from "./Components/Admin/Investorledger/Investorledger/Tables";
import Inward from "./Components/Admin/Inward/Inward/Tables";
import OutWard from "./Components/Admin/Outward/Outward/Tables";
import Notification from "./Components/Admin/Notification/Notification/Tables";
import Operation from "./Components/Admin/Operations/Operations/Tables";
import RateUs from "./Components/Admin/RateUs/RateUs/Tables";
import Pnl from "./Components/Admin/PnL/Pnl/Table";
import Pnlclient from "./Components/Admin/PnL/Pnl_client/Tables";
import PnlTransaction from "./Components/Admin/PnL/PnlTransaction/Tables";
import { Context } from "./utils/context";
import { Calendar } from "antd";

library.add(fas);

const App = () => {
  const { signin } = useContext(Context);
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen((prevOpen) => !prevOpen);
  };

  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  // };

  // const handleLogout = () => {
  //   setIsLoggedIn(false);
  // };

  // useEffect(() => {
  //   setIsLoggedIn(location.pathname !== "/login");
  // }, [location.pathname]);

  return (
    <div className="top_section">
      {signin && (
        <>
          <div className="sub-header">
            <Row>
              <Col xxl={6} xl={6} lg={6}>
                <div className="main-sub-header">
                  <h3 className="Itlogtitle">ProfitKing ADMIN</h3>
                  <div className="icon">
                    <FaBars onClick={toggleSidebar} />
                  </div>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6}>
                <div className="admin-header">
                  <div className="user">
                    <FontAwesomeIcon
                      icon={["fas", "user"]}
                      className="user-icon"
                    />
                  </div>
                  <div className="text">
                    <p>Profitking/Admin</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className={`${
              sidebarOpen ? "row ms-0 me-0" : " d-flex "
            } main-content`}
          >
            <div
              className={`ps-0 ${
                sidebarOpen
                  ? "col-xxl-2 col-xl-2 col-lg-3 col-md-2 p-0"
                  : "sidebar-column"
              } `}
            >
              <Sidebar isOpen={sidebarOpen} />
            </div>
            <div
              className={`${
                sidebarOpen
                  ? "col-xxl-10 col-xl-10 col-lg-9 p-0"
                  : " main-content"
              } `}
            >
              <Routes>
                {/* <Route path="/" element={<Navigate to="/" />} /> */}
                <Route path="/" element={<Login />} />
                <Route path="/header" element={<Header />} />
                <Route path="/dashboard" element={<DashboardMain />} />
                <Route path="/orders" element={<Order />} />
                <Route path="/investor" element={<Investor />} />
                <Route path="/investor-ledger" element={<Investorledger />} />
                <Route path="/inward" element={<Inward />} />
                <Route path="/outward" element={<OutWard />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/rate-us" element={<RateUs />} />{" "}
                <Route path="/pnl" element={<Pnl />} />
                <Route path="/pnl-client" element={<Pnlclient />} />
                <Route path="/pnl-transaction" element={<PnlTransaction />} />
                <Route path="/operations-main" element={<Operation />} />
                {/* Masters Routes */}
                <Route path="/masters/country" element={<Country />} />
                <Route path="/masters/state" element={<State />} />
                <Route path="/masters/city" element={<City />} />
                <Route path="/masters/pincode" element={<Pincode />} />
                <Route path="/masters/occupation" element={<Occupation />} />
                <Route path="/masters/faq" element={<FAQ />} />
                <Route path="/masters/app-setup" element={<AppSetup />} />
                <Route path="/masters/calendar" element={<Calendars />} />
                {/* Payment Routes */}
                <Route
                  path="/payment/inward-payment"
                  element={<DashboardMain />}
                />
              </Routes>
            </div>
          </div>
        </>
      )}

      {!signin && <Login />}
    </div>
  );
};

export default App;
