import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Submit } from "../../../common/Button";
import {
  InwardAccepted,
  InwardManual
} from "../../../../utils/apis/Inward/Inward";
import { formatDate } from "../../../../utils/common";
import Pagination from "../../../common/Pagination";

import ScreenShot from "../../../common/ScreenShot";
import { Context } from "../../../../utils/context";
import { getAllData,Delete } from "../../../../utils/apis/Inward/Inward";
// import DataTable from "react-data-table-component";
import { FaTrash } from 'react-icons/fa'; 
export const Investor = ({
  status,
  setStatus,
  handleExportCSV,
  handlePrint,
  handleExportExcel,
}) => {
  const { IMG_URL } = useContext(Context);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    reset,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
    // alert(JSON.stringify(data));
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);

  const getData = async (search = "", name = "") => {
    let res;
    if (search === "") {
      res = await getAllData(currentPage, perPage, search, name);
    } else {
      res = await getAllData(currentPage, perPage, search, name);
    }

    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  const Accepted = async (id) => {
    const amount = getValues(`amount_${id}`);

    const data = {
      amount: amount,
      id: id,
    };
    if (amount > 0) {
      const res = await InwardManual(data);
      if (res.success) {
        getData();
        setStatus(res.success);
      }
      // getData();
      // Proceed with your logic here
    } else {
      await setError(`amount_${id}`, {
        type: "manual",
        message: "Enter amount",
      });
    }
  };

  // Columns for the Data Table
  // const columns = [
  //   {
  //     name: "Payment Date",
  //     selector: (row) => formatDate(row.createdAt),
  //     sortable: true,
  //   },
  //   {
  //     name: "Investor Name",
  //     selector: (row) => `${row.user.f_name} ${row.user.l_name}`,
  //     sortable: true,
  //   },
  //   {
  //     name: "State",
  //     selector: (row) => row.user.users_detail.state.name,
  //     sortable: true,
  //   },
  //   {
  //     name: "District",
  //     selector: (row) => row.user.users_detail.city.name,
  //     sortable: true,
  //   },
  //   {
  //     name: "Age",
  //     selector: (row) => row.user.users_detail.age,
  //     sortable: true,
  //   },
  //   {
  //     name: "Email",
  //     selector: (row) => row.user.email,
  //     sortable: true,
  //   },
  //   {
  //     name: "Payment Screenshot",
  //     selector: (row) => "7", // Replace '7' with actual data if available
  //     sortable: true,
  //   },
  //   {
  //     name: "Amount",
  //     cell: (row) => (
  //       <div className="form-group me-2">
  //         <input
  //           type="number"
  //           placeholder="Amount"
  //           {...register(`amount_${row.id}`, {
  //             required: "amount is required",
  //           })}
  //           className={`form-control ${
  //             errors[`amount_${row.id}`] ? "is-invalid" : ""
  //           }`}
  //         />
  //       </div>
  //     ),
  //   },
  //   {
  //     name: "Action",
  //     cell: (row) => <Submit />,
  //   },
  // ];
  const SegamentDelete = async (id) => {
    const res = await Delete(id);
    getData();
  };

  return (
    <div className="search-investor mt-3">
      <div className="investor-main">
        <FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-3" />
        <p className="sub">Investor</p>
      </div>
      <div className="inward-payment">
        <div className="nav-link active">
          {/* <div className="dt-buttons btn-group flex-wrap">
            <button
              className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handleExportExcel(data, "Investor")}
            >
              <span>Excel</span>
            </button>
            <button
              className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handleExportCSV(data, "Investor")}
            >
              <span>CSV</span>
            </button>
            <button
              className="btn btn-secondary buttons-print Print_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handlePrint(data, "Investor")}
            >
              <span>Print</span>
            </button>
          </div> */}
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="simple-form outward-main"
        >
          <div className="form-group">
            <label htmlFor="name">Investor Name: </label>
            <input
              type="text"
              id="name"
              onChange={(e) => {
                setValue('investor', e.target.value);
                getData(getValues('service_no'), e.target.value);
              }}
              placeholder="Serch By Investor Name"
              // {...register("name", { required: "Name is required" })}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />

            <label htmlFor="name">Search: </label>
            <input
              type="text"
              id="name"
              onChange={(e) => {
                setValue('service_no', e.target.value);
                getData(e.target.value, getValues('investor'));
              }}
              placeholder="Serch By Service No"
              // {...register("name", { required: "Name is required" })}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
            )}
          </div>
        </form>
      </div>
      {/* table started */}
      <div className="table-main">
        <div className="table-responsive mt-2">
          {/* <DataTable columns={columns} data={data?.data || []}  /> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <table className="table table-bordered  table-striped">
              <thead>
                <tr>
                  <th className="name">
                    Payment Date
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>
                  <th className="name">
                    Investor Name
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>
                  <th className="name">
                    State
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>
                  <th className="name">
                    District
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>
                  <th className="name">
                    Age
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>
                  <th className="name">
                    Service No
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>
                  <th className="name">
                    UTR No
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>

                  <th className="name">
                    Amount
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>
                  <th className="name">
                    Action
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {data?.data?.map((data, index) => (
                <tr className="odd" key={index}>
                  <td>{formatDate(data?.createdAt)}</td>
                  <td>
                    {data?.user?.f_name} {data?.user?.l_name}
                  </td>
                  <td>{data?.user?.users_detail?.state?.name}</td>
                  <td>{data?.user?.users_detail?.city?.name}</td>
                  <td>{data?.user?.users_detail?.age}</td>
                  <td>{data?.user?.email}</td>
                  <td>7</td>
                  <td>
                    <div className="form-group me-2">
                      <input
                        type="number"
                        placeholder="Amount"
                        {...register("amount", {
                          // required: "amount is required",
                        })}
                        className={`form-control ${
                          errors.amount ? "is-invalid" : ""
                        }`}
                      />
                    </div>
                  </td>
                  <td>
                    <Submit handleSubmit={() => Accepted(data?.id)} />
                  </td>
                </tr>
              ))} */}

                {data?.data?.map((data, index) => (
                  <tr className="odd" key={index}>
                    <td>{formatDate(data?.createdAt)}</td>
                    <td>
                      {data?.user?.f_name} {data?.user?.l_name}
                    </td>
                    <td>{data?.user?.users_detail?.state?.name}</td>
                    <td>{data?.user?.users_detail?.city?.name}</td>
                    <td>{data?.user?.users_detail?.age}</td>
                    <td>{data?.user?.contact_no}</td>
                    <td>{data?.utr_id}</td>

                    <td>
                      <div className="form-group me-2">
                        <input
                          type="number"
                          placeholder="Amount"
                          {...register(`amount_${data.id}`, {})}
                          className={`form-control ${errors[`amount_${data.id}`] ? "is-invalid" : ""
                            }`}
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          Accepted(data.id);
                        }}
                        type="button"
                        className="btn btn-primary"
                      >
                        Add Amount
                      </button>
                      
                      <button
                        onClick={() => {
                          SegamentDelete(data.id);
                        }}
                        type="button"
                        className="btn btn-danger"
                        style={{ marginLeft: '10px' }}
                      >
                        <FaTrash />
                      </button>
                    </td>


                  </tr>
                ))}
              </tbody>
            </table>
          </form>
        </div>
      </div>
      {/* table ended */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        TotalPages={TotalPages}
        TotalEntries={TotalEntries}
        perPage={perPage}
      />

      <ScreenShot show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};
