import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (page, per_page, search) => {
  try {
    let url = `/notifications/notifications?`;

    if (page) {
      url += `&page=${page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Create = async (data) => {
  try {
    return await postData(`/notifications/notifications`, data);
  } catch (error) {
    console.error(error);
  }
};

// export const Delete = async (id) => {
//   try {
//     return await deleteData(`/notifications/notifications/${id}`);
//   } catch (error) {
//     console.error(error);
//   }
// };
