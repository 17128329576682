import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import { InwardAccepted, InwardEdit } from "../../utils/apis/Inward/Inward";
import { Context } from "../../utils/context";
import Select from "react-select";
import { kycStatus } from "../../utils/apis/Investores/Investores";
import Col from "react-bootstrap/Col";

import Row from "react-bootstrap/Row";
import { formatDate } from "../../utils/common";
import { error } from "jquery";

const ScreenShot = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
    control,
    reset,
  } = useForm();

  const options = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
  ];

  const [reasonShow, setReasonShow] = useState(false);
  const [previewImage, setPreviewImage] = useState("");




  const { IMG_URL } = useContext(Context);

  console.log(errors);
  console.log(getValues());
  const onSubmit = async (d) => {

   

      const data = {
        verify_status: d?.verify_status?.value,
        remark: d?.remark,
        id: props.kycID?.kyc_detail?.id,
        type:props?.detailType, 
      };
   
     
         const res = await kycStatus(data);
      
      if (res.success) {
        props.getData();
        setReasonShow(false);
        props.onHide();
      }else{
        setError('api',{message:res?.message});
      }
   
    
    // getData();
    // Proceed with your logic here

  };

  console.log(errors);


  useEffect(() => {
    reset();

    if(props.detailType === 'KYC'){
      if (props.kycID?.kyc_detail?.verify_status === "Rejected") {
        setReasonShow(true);
        setValue('remark', props.kycID?.kyc_detail?.remark);
      } else {
        setReasonShow(false);
        setValue('remark', "");
      }
  
      setValue('verify_status', { value: props.kycID?.kyc_detail?.verify_status, label: props.kycID?.kyc_detail?.verify_status });
    }else{
      if (props.kycID?.kyc_detail?.bank_status === "Rejected") {
        setReasonShow(true);
        setValue('remark', props.kycID?.kyc_detail?.remark);
      } else {
        setReasonShow(false);
        setValue('remark', "");
      }
  
      setValue('verify_status', { value: props.kycID?.kyc_detail?.bank_status, label: props.kycID?.kyc_detail?.bank_status });
    }
   
  }, [props.show]);



  return (
    <>
      <Modal
        {...props}
        size="xl"
        className="Payment_Screenshotmodal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.detailType === 'KYC' ? "KYC Verify" : "Bank Verify"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <Row>
            <Col xxl={8} xl={8} lg={8} style={{ borderRight: "1px solid #000" }}>

              <Row>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>Registered Date :</b> {props?.kycID?.createdAt ? formatDate(props?.kycID?.createdAt) : ''}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>Investor Name :</b> {props?.kycID?.f_name} {props?.kycID?.l_name}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>Service No. :</b> {props?.kycID?.contact_no}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>State :</b> {props?.kycID?.users_detail?.state?.name}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>District :</b> {props?.kycID?.users_detail?.city?.name}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>Age :</b> {props?.kycID?.users_detail?.age}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>Gender :</b> {props?.kycID?.users_detail?.gender}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>Holder Name :</b> {props?.kycID?.bank_account?.holder_name}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>Ac No. :</b> {props?.kycID?.bank_account?.ac_no}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <b>IFSC Code :</b> {props?.kycID?.bank_account?.ifsc_code}
                  </div>
                </Col>
              </Row>
              <div className="mt-5">
                <div className="d-flex">
                  <p>
                    <b>Uploaded Photo:</b>
                  </p>
                  <img src={IMG_URL + props?.kycID?.kyc_detail?.image} width={100} onClick={() => setPreviewImage(IMG_URL + props?.kycID?.kyc_detail?.image)} />

                  <p>
                    <b>Aadhar Photo Front:</b>
                  </p>
                  <img src={IMG_URL + props?.kycID?.kyc_detail?.aadhar_photo} width={100} onClick={() => setPreviewImage(IMG_URL + props?.kycID?.kyc_detail?.aadhar_photo)} />

                  <p>
                    <b>Aadhar Photo Back:</b>
                  </p>
                  <img src={IMG_URL + props?.kycID?.kyc_detail?.aadhar_photo_back} width={100} onClick={() => setPreviewImage(IMG_URL + props?.kycID?.kyc_detail?.aadhar_photo_back)} />
                </div>
                <div className="d-flex mt-5">
                  <p>
                    <b>Pan Card:</b>
                  </p>
                  <img src={IMG_URL + props?.kycID?.kyc_detail?.pan_card} width={100} onClick={() => setPreviewImage(IMG_URL + props?.kycID?.kyc_detail?.pan_card)} />
                  <p>
                    <b>PassBook/Cheque:</b>
                  </p>
                  <img src={IMG_URL + props?.kycID?.kyc_detail?.passbook} width={100} onClick={() => setPreviewImage(IMG_URL + props?.kycID?.kyc_detail?.passbook)} />
                </div>


              </div>
            </Col>

            <Col xxl={4} xl={4} lg={4}>
              <div className="mt-5">
                <img src={previewImage} style={{ width: "100%" }} />
              </div>
            </Col>
          </Row>

          <div className="mt-4">


            {reasonShow && (
              <>
                <input
                  type="text"
                  id="firstName"
                  {...register("remark", {
                    // required: "Reason is required"
                  })}
                  className="form-control"

                  placeholder="Reason for Rejection"
                />
                <span className="text-danger">{errors?.remark?.message}</span>
              </>
            )}
          </div>

          <div className="d-flex mt-5">
            <Controller
              name="verify_status"
              {...register("verify_status", {
                required: "Status is required"
              })}
              control={control}
              render={({ field }) => (
                <Select
                  className="select-drop"
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: errors.verify_status
                        ? "red"
                        : baseStyles,
                    }),
                  }}
                  classNamePrefix="custom-select-class"
                  placeholder="Select Status"
                  {...field}
                  options={options}
                  onChange={(selectedOption) => {
                    setValue('verify_status', selectedOption);
                    if (selectedOption?.value === "Rejected") {
                      setReasonShow(true);
                    } else {
                      setReasonShow(false);
                    }
                  }}
                />
              )}
            />
            <button
              type="button"
              className="btn btn-success ms-3"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </button>
          </div>

          <div className="d-flex mt-5">
            <span className="text text-danger">{errors?.api?.message}</span>
          </div>
          




          {/* <div className="">
            <div className="d-flex">
              <p>
                <b>Uploaded Photo:</b>
              </p>
              <img src={IMG_URL + props?.kycID?.kyc_detail?.image} width={100} />

              <p>
                <b>Aadhar Photo Front:</b>
              </p>
              <img src={IMG_URL + props?.kycID?.kyc_detail?.aadhar_photo} width={100} />

              <p>
                <b>Aadhar Photo Back:</b>
              </p>
              <img src={IMG_URL + props?.kycID?.kyc_detail?.aadhar_photo_back} width={100} />
            </div>
            <div className="d-flex mt-5">
              <p>
                <b>Pan Card:</b>
              </p>
              <img src={IMG_URL + props?.kycID?.kyc_detail?.pan_card} width={100} />
              <p>
                <b>PassBook/Cheque:</b>
              </p>
              <img src={IMG_URL + props?.kycID?.kyc_detail?.passbook} width={100} />
            </div>

            <div className="mt-4">


              {reasonShow && (
                <>
                  <input
                    type="text"
                    id="firstName"
                    {...register("remark", {
                      required: "Reason is required"
                    })}
                    className="form-control"

                    placeholder="Reason for Rejection"
                  />
                  <span className="text-danger">{errors?.remark?.message}</span>
                </>
              )}
            </div>

            <div className="d-flex mt-5">
              <Controller
                name="verify_status"
                {...register("verify_status", {
                  required: "Status is required"
                })}
                control={control}
                render={({ field }) => (
                  <Select
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: errors.verify_status
                          ? "red"
                          : baseStyles,
                      }),
                    }}
                    classNamePrefix="custom-select-class"
                    placeholder="Select Status"
                    {...field}
                    options={options}
                    onChange={(selectedOption) => {
                      setValue('verify_status', selectedOption);
                      if (selectedOption?.value === "Rejected") {
                        setReasonShow(true);
                      } else {
                        setReasonShow(false);
                        setError('remark',"");
                        setValue('remark', "");
                      }
                    }}
                  />
                )}
              />
              <button
                type="button"
                className="btn btn-success ms-3"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </button>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScreenShot;
