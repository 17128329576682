import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (page, per_page, search,sDate, eDate) => {
  try {
    let url = `/pnl/pnl?`;

    if (page) {
      url += `&page=${page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    if (sDate) {
      url += `&sDate=${sDate}`;
    }

    if (eDate) {
      url += `&eDate=${eDate}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Create = async (data) => {
  try {
    return await postData(`/pnl/pnl-client`, data);
  } catch (error) {
    console.error(error);
  }
};

export const getAllDataPnlClient = async (page, per_page, search) => {
  try {
    let url = `/pnl/pnl-client?`;

    if (page) {
      url += `&page=${page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const getAllDataPnlTransaction = async (page, per_page, search) => {
  try {
    let url = `/pnl/pnl-transaction?`;

    if (page) {
      url += `&page=${page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const CreatePnlTransaction = async (data) => {
  try {
    return await postData(`/pnl/pnl-transaction`, data);
  } catch (error) {
    console.error(error);
  }
};
// export const Create = async (data) => {
//   try {
//     return await postData(`/notifications/notifications`, data);
//   } catch (error) {
//     console.error(error);
//   }
// };

export const DeletePnlTransaction = async (id) => {
  try {
    return await deleteData(`/pnl/pnl-transaction/${id}`);
  } catch (error) {
    console.error(error);
  }
};
