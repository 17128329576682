import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  // const [imagePreview, setImagePreview] = useState(null);
  // const [errors, setErrors] = useState();
  const id = props.show;

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  // const [formData, setFormData] = useState({
  //   upi_id: "",
  //   ac_no: "",
  //   operator_charges: "",
  //   refer_percentage: "",
  // });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };
  // const handleChange = (e) => {
  //   if (e.target.type === "file") {
  //     const selectedImage = e.target.files[0];

  //     // Check if a file is selected
  //     if (selectedImage) {
  //       // Generate image preview
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setImagePreview(reader.result);
  //       };
  //       reader.readAsDataURL(selectedImage);

  //       // Update formData with the selected image
  //       setFormData({ ...formData, image: selectedImage });
  //     } else {
  //       // User canceled image selection, clear the image preview and formData
  //       setImagePreview(null);
  //       setFormData({ ...formData, image: null });
  //     }
  //   } else {
  //     // Handle text input
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = Validation.FormValidation({
  //     classname: ".AppSetupclass",
  //   });

  //   if (validationErrors) {
  //     try {
  //       const formDataToSend = new FormData();
  //       formDataToSend.append("upi_id", formData.upi_id);
  //       formDataToSend.append("ac_no", formData.ac_no);
  //       formDataToSend.append("operator_charges", formData.operator_charges);
  //       formDataToSend.append("refer_percentage", formData.refer_percentage);
  //       formDataToSend.append("mini_withdrawn", formData.mini_withdrawn);
  //       formDataToSend.append("mini_fund", formData.mini_fund);
  //       formDataToSend.append("ifsc_code", formData.ifsc_code);
  //       // formDataToSend.append("waiting_charges", formData.waiting_charges);
  //       // formDataToSend.append("image", formData.image);
  //       const response = await postData(
  //         `/masters/app-setup/${id}`,
  //         formDataToSend
  //       );

  //       if (response.success) {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       } else {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  const onSubmit = async (data) => {
    try {
      const response = await postData(`/masters/app-setup/${id}`, data);

      if (response.success) {
        setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 1000);
      } else {
        setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/app-setup/${id}`);
    reset(response?.data);
    // setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit App Setup</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit App Setup</Card.Title>
              <hr />
              <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <Container>
                        <Row className="">
                          <Col xxl={12} xl={12} md={10}>
                            <Form
                              onSubmit={handleSubmit}
                              role="form"
                              className="AppSetupclass"
                            >
                              <Row>
                                <Col
                                  lg={8}
                                  md={10}
                                  className=" mx-auto Add-content"
                                >
                                  <Row>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            UPI ID
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="upi_id"
                                                  {...register("upi_id", {
                                                    required:
                                                      "UPI ID is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="UPI ID"
                                                />
                                                <span className="error-message"></span>
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            A/c No
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="number"
                                                  name="ac_no"
                                                  {...register("ac_no", {
                                                    required:
                                                      "A/c No is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="A/c No"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            IFSC Code
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="ifsc_code"
                                                  {...register("ifsc_code", {
                                                    required:
                                                      "IFSC Code is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="IFSC Code"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Operator Charges
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="number"
                                                  name="operator_charges"
                                                  {...register(
                                                    "operator_charges",
                                                    {
                                                      required:
                                                        "Operator Charges is required",
                                                    }
                                                  )}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Operator Charges"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Refer Percentage
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="number"
                                                  name="refer_percentage"
                                                  {...register(
                                                    "refer_percentage",
                                                    {
                                                      required:
                                                        "Refer Percentage is required",
                                                      pattern: {
                                                        value:
                                                          /^[1-9][0-9]?$|^100$/,
                                                        message:
                                                          "Please enter a number between 1 and 100",
                                                      },
                                                    }
                                                  )}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Refer Percentage"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Mini Withdrawn
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="number"
                                                  name="mini_withdrawn"
                                                  {...register(
                                                    "mini_withdrawn",
                                                    {
                                                      required:
                                                        "Mini Withdrawn is required",
                                                    }
                                                  )}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Mini Withdrawn"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Mini Fund
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="number"
                                                  name="mini_fund"
                                                  {...register("mini_fund", {
                                                    required:
                                                      "Mini Fundes is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Mini Fundes"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Capital Amount (To show users)
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="number"
                                                  name="capital_amount"
                                                  {...register("capital_amount", {
                                                    required:
                                                      "Capital Amount is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Capital Amount"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Mail Us
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="email"
                                                  name="contact_us"
                                                  {...register("contact_us", {
                                                    required:
                                                      "Email is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Email"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>

                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Call Us
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="number"
                                                  name="whats_app_no"
                                                  {...register("whats_app_no", {
                                                    required:
                                                      "Whats's App No is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Whats's App No"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Whats's App Channel Link
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="whats_app_link"
                                                  {...register("whats_app_link", {
                                                    required:
                                                      "Whats's App Channel is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Whats's App Channel Link"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Tutorial Link
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="tutorial_link"
                                                  {...register("tutorial_link", {
                                                    required:
                                                      "Tutorial Link is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Tutorial Link"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Corporate Office Address
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  as="textarea"
                                                  name="corporate_address"
                                                  {...register("corporate_address", {
                                                    required:
                                                      "Corporate Address is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Corporate Address"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Call Time
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="call_time"
                                                  {...register("call_time", {
                                                    required:
                                                      "Call Us Time is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Call Us Time"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Capital Withdraw Time
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="capital_time"
                                                  {...register("capital_time", {
                                                    required:
                                                      "Capital Withdraw Time is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Capital Withdraw Time"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Profit Withdraw Time
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="profit_time"
                                                  {...register("profit_time", {
                                                    required:
                                                      "Profit Withdraw Time is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Profit Withdraw Time"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Refferal Content
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  as="textarea"
                                                  name="refferal_content"
                                                  {...register("refferal_content", {
                                                    required:
                                                      "Refferal Content is required",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Refferal Content"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Refund Policy Link
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="refund_policy_link"
                                                  {...register("refund_policy_link", {
                                                    required:
                                                      "Refund Policy Link",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Refund Policy Link"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Terms Conditions Link
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="terms_conditions_link"
                                                  {...register("terms_conditions_link", {
                                                    required:
                                                      " Terms Conditions Link",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder=" Terms Conditions Link"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            About Us Link
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="about_us_link"
                                                  {...register("about_us_link", {
                                                    required:
                                                      "About Us Link",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="About Us Link"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            Privacy Policy Link
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="privacy_policy_link"
                                                  {...register("privacy_policy_link", {
                                                    required:
                                                      "Privacy Policy Link",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="Privacy Policy Link"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={12}>
                                            App Version
                                          </Form.Label>
                                          <Col sm={12}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="app_version"
                                                  {...register("app_version", {
                                                    required:
                                                      "App Version",
                                                  })}
                                                  className={`form-control ${errors.name
                                                      ? "is-invalid"
                                                      : ""
                                                    }`}
                                                  placeholder="App Version"
                                                  id="inputEmail3"
                                                />{" "}
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  {/* <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Helpline No
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="number"
                                                name="helpline_no"
                                                value={formData?.helpline_no}
                                                onChange={handleChange}
                                                placeholder="Helpline No"
                                                className="input-mandatory"
                                                id="inputEmail3"
                                              />{" "}
                                              <span className="error-message"></span>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="justify-content-center mt-3">
                                  <Form.Label column sm={3}>
                                    Image
                                  </Form.Label>
                                  <Col sm={6}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="file"
                                          name="image"
                                          accept="image/*"
                                          onChange={handleChange}
                                        />
                                      </InputGroup>
                                      {errors?.image && (
                                        <span style={errorStyle}>
                                          {errors?.image}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>        
                                  <Col md={4}>
                                    {imagePreview ? (
                                      <img
                                        src={imagePreview}
                                        alt="Image Preview"
                                        className="table-image"
                                        style={{
                                          height: "100px",
                                          width: "150px",
                                          margin: "4px",
                                          marginLeft: "150px",
                                          backgroundSize: "fixed",
                                        }}
                                      />
                                    ) : (
                                      formData?.image && (
                                        <img
                                          src={IMG_URL + formData.image}
                                          alt={formData?.image || "Image"}
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "150px",
                                            marginTop: "4px",
                                            marginLeft: "150px",
                                            backgroundSize: "fixed",
                                          }}
                                        />
                                      )
                                    )}
                                  </Col>
                                </Row> */}

                                  <Row className="mt-5 pb-3">
                                    <div className="d-flex justify-content-center">
                                      <Link>
                                        <CancelButton
                                          name={"cancel"}
                                          handleClose={props.handleClose}
                                        />
                                      </Link>
                                      <SaveButton
                                        name={"save"}
                                        handleSubmit={handleSubmit(onSubmit)}
                                      />
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </form>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
