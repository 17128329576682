import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import {
  AddButton,
  EditButton,
  DeletButton,
  Submit,
} from "../../../common/Button";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ScreenShot from "../../../common/ScreenShot";
import Users from "./Users";
import Reports from "./Reports";
import ExportMethod from "../../../../utils/apis/ExportMethod";
import { formatDate } from "../../../../utils/common";

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  // hok form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  // table

  const [modalShow, setModalShow] = useState(false);

  const [status, setStatus] = useState();

  const handleExportCSV = (data, file) => {
    const header = [
      { name: "Payment Date" },
      { name: "Investor Name" },
      { name: "Service No" },
      { name: "State" },
      { name: "District" },
      { name: "A/C No" },
      { name: "IFSC Code" },
      { name: "Withdrawal Amount" },
      { name: "Status" },
    ];
    const exportData = data?.data?.map((data) => [
      formatDate(data.createdAt),
      data?.user?.f_name + " " + data?.user?.l_name,
      data?.user?.contact_no,
      data?.user?.users_detail?.state?.name,
      data?.user?.users_detail?.city?.name,
      data?.ac_no,
      data?.ifsc_code,
      data?.amount,
      data?.accepted_rejected ? "Accepted" : "Rejected",
    ]);
    const { content, type, name } = ExportMethod.csv(exportData, header, file);
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = (data, file) => {
    console.log(data);
    const header = [
      { name: "Payment Date" },
      { name: "Investor Name" },
      { name: "Service No" },
      { name: "State" },
      { name: "District" },
      { name: "A/C No" },
      { name: "IFSC Code" },
      { name: "Withdrawal Amount" },
      { name: "Status" },
    ];
  console.log(data?.data,"data?.data data?.data");
  
    const exportData = data?.data?.map((data) => [
      formatDate(data.createdAt),
      data?.user?.f_name + " " + data?.user?.l_name,
      data?.user?.contact_no,
      data?.user?.users_detail?.state?.name,
      data?.user?.users_detail?.city?.name,
      // data?.ac_no?.toString(),
      "'" + data?.ac_no?.toString()+"'",
      data?.ifsc_code,
      data?.amount,
      data?.accepted_rejected ? "Accepted" : "Rejected",
    ]);

    console.log(exportData);
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      file
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handlePrint = (data, file) => {
    const header = [
      { name: "Payment Date" },
      { name: "Investor Name" },
      { name: "Service No" },
      { name: "State" },
      { name: "District" },
      { name: "A/C No" },
      { name: "IFSC Code" },
      { name: "Withdrawal Amount" },
      { name: "Status" },
    ];
    const exportData = data?.data?.map((data) => [
      formatDate(data.createdAt),
      data?.user?.f_name + " " + data?.user?.l_name,
      data?.user?.contact_no,
      data?.user?.users_detail?.state?.name,
      data?.user?.users_detail?.city?.name,
      data?.ac_no,
      data?.ifsc_code,
      data?.amount,
      data?.accepted_rejected ? "Accepted" : "Rejected",
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Outward Payment"} link={"/outward"} />

        <Users
          status={status}
          setStatus={setStatus}
          handleExportCSV={handleExportCSV}
          handlePrint={handlePrint}
          handleExportExcel={handleExportExcel}
        />
        <Reports
          status={status}
          setStatus={setStatus}
          handleExportCSV={handleExportCSV}
          handlePrint={handlePrint}
          handleExportExcel={handleExportExcel}
        />
      </div>

      {/* {show ? <AddOffCanvance handleClose={handleClose} setShow={setShowAdd} show={show} /> : ""}

            {show1 ? <EditOffCanvance handleClose={handleClose1} setShow={setShowEdit} show={show1} /> : ""}
            <ModalDelete
                show={showDeleteModal}
                handleDeleteRecord={handleDeleteRecord}
                handleDeleteCancel={handleDeleteCancel}
            /> */}

      {/* <Toaster position="top-right" /> */}
    </>
  );
};

export default Tables;
