import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Common.css";
const Modallogout = (props) => {
  return (
    <>
      <div className="Logout">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Logout"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text">Are You Sure You want To Logout?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.LogOut}>Yes</Button>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Modallogout;
