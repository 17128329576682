import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-js-loader";

import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  Create,
  getAllDataPnlClient,
} from "../../../../utils/apis/PnL/PnL";
import { Select2Data, formatDate } from "../../../../utils/common";
import Pagination from "../../../common/Pagination";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ExportMethod from "../../../../utils/apis/ExportMethod";
import {
  City,
  Occupation,
  State,
} from "../../../../utils/apis/masters/masters";

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  // hok form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const { IMG_URL } = useContext(Context);

  const [activeStatus, setActiveStatus] = useState("All");
  const [clientIdError, setClientIdError] = useState(null);
  const handleRadioChange = (status) => {
    setActiveStatus(status);
    // You can perform additional actions based on the selected status here
  };

  console.log(activeStatus,'lll');

  const [shimmerLoader, setShimmerLoader] = useState(false);

  const onSubmit = async (data) => {
    
    setShimmerLoader(true);
    const finalData = new FormData();
  
    finalData.append("capital", data?.capital);
    finalData.append("client_name", data?.client_name);
    finalData.append("client_id", data?.client_id);
    finalData.append("pan", data?.pan);
    console.log(finalData, "lllllll");
    const res = await Create(finalData);
    if (res.success) {
      // reset();
      setShimmerLoader(false);
      getData();
      reset();
      setClientIdError();
      setValue('capital',"");
      setValue('client_name',"");
      setValue('client_id',"");
      setValue('pan',"");
    
    }else{
      console.log(res.message, "Error message");
      setClientIdError(res?.message);
      setShimmerLoader(false);
    }
  };
  console.log(clientIdError,"clientIdError clientIdError");

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);

  const getData = async (search = "") => {
    const res = await getAllDataPnlClient(currentPage, perPage, search);
    setData(res?.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  const Clicked = async () => {
    console.log("Clicked");
  };
  const columns = [
    {
      name: "Date",
      selector: "createdAt",
      sortable: true,
      cell: (d) => formatDate(d.createdAt),
    },
    {
      name: "Notification Message",
      selector: "name",
      sortable: true,
      // cell: (row) => <button onClick={() => Clicked()}>{row.name}</button>,
    },
  ];

  const tableData = {
    columns,
    data: data.data,
  };

  // Exports
  const handleExportCSV = () => {
    const header =[{ name: "Client Name" }, { name: "Client Id" }, { name: "Pan" }, { name: "Capital" }];
    const exportData = data?.data?.map((d) => [
      d.client_name,
      d.client_id,
      d.pan,
      d.capital,
    ]);
    const { content, type, name } = ExportMethod.csv(
      exportData,
      header,
      "Pnl Client"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = () => {
    const header = [{ name: "Client Name" }, { name: "Client Id" }, { name: "Pan" }, { name: "Capital" }];
    const exportData = data?.data?.map((d) => [
      d.client_name,
      d.client_id,
      d.pan,
      d.capital,
     
    
    ]);
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "Pnl Client"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handlePrint = () => {
    const header = [{ name: "Client Name" }, { name: "Client Id" }, { name: "Pan" }, { name: "Capital" }];
    const exportData = data?.data?.map((d) => [
      d.client_name,
      d.client_id,
      d.pan,
      d.capital,
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close("", "");
    printWindow.print();
  };

  const [state, setState] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [city, setCity] = useState([]);
  const getMastersData = async () => {
    {
      const res = await State();
      await setState(await Select2Data(res?.data, "state_id"));
    }
    {
      const res = await Occupation();
      await setOccupation(await Select2Data(res?.data, "occupation_id"));
    }
  };

  const handleStateSelect = async (id) => {
    setValue("city_id", "");
    const res = await City(id);
    if (res?.success) {
      await setCity(await Select2Data(res?.data, "city_id"));
    }
  };

  useEffect(() => {
    getMastersData();
  }, []);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Pnl Client"} link={"/notification"} />
        <div className="search-investor mt-3">
          <div className="notification">
            <Row>
              <Col xxl={12}>
                <div className="form-group">
                             
                  <label htmlFor="name me-4">Client Name: </label>
                  <input
                    type="text"
                    id="client_name"
                    placeholder="Enter Client Name"
                    {...register("client_name", {
                      required: "Clien Name is required",
                    })}
                    className={`form-control ${errors.client_name ? "is-invalid" : ""
                      }`}
                  />

                  <label htmlFor="name me-4">Client ID: </label>
                  <input
                    type="text"
                    id="client_id"
                    placeholder="Client ID"
                    {...register("client_id", {
                      required: "Client ID is required",
                    })}
                    className={`form-control ${errors.client_id || clientIdError ? "is-invalid" : ""}`}
                    // className={`form-control ${errors.client_id ? "is-invalid" : ""
                    //   }`}
                  />
                   <div className="invalid-feedback">{clientIdError}</div>
                    <label htmlFor="name me-4">Pan: </label>
                  <input
                    type="text"
                    id="pan"
                    placeholder="Pan"
                    {...register("pan", {
                      required: "Pan is required",
                      pattern: {
                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Invalid PAN format"
                      }
                    })}
                    className={`form-control ${errors.pan ? "is-invalid" : ""
                      }`}
                  />
                   {errors.pan && <div className="invalid-feedback">{errors.pan.message}</div>}
                    <label htmlFor="name me-4">Capital: </label>
                  <input
                    type="number"
                    id="capital"
                    placeholder="Capital"
                    {...register("capital", {
                      required: "capital is required",
                    })}
                    className={`form-control ${errors.capital ? "is-invalid" : ""
                      }`}
                  />
                  {/* {errors.name && (
                          <div className="invalid-feedback">
                            {errors.name.message}
                          </div>
                        )} */}
                </div>
              </Col>
              <Col xxl={12}>
              
              </Col>
             
             
              <Col xxl={6} className="mt-3">

              {shimmerLoader ? (
                     <Loader type="bubble-top" bgColor={"#000"} color={"#000"} size={50} />
                    ) : (
                <Button
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                  className="submit"
                >
                  Submit
                </Button>
                    )}
              </Col>
            </Row>
          </div>
        </div>
        <div className="search-investor mt-4">
          <div className="investor-main">
            <FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-3" />
            <p className="sub">Pnl Client History</p>
          </div>

          <div className="inward-payment">
            <div className="nav-link active">
              <div className="dt-buttons btn-group flex-wrap">
                <button
                  className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handleExportExcel}
                >
                  <span>Excel</span>
                </button>
                <button
                  className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handleExportCSV}
                >
                  <span>CSV</span>
                </button>
                <button
                  className="btn btn-secondary buttons-print Print_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handlePrint}
                >
                  <span>Print</span>
                </button>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="simple-form outward-main"
            >
              <div className="form-group">
                <label htmlFor="name">Search: </label>
                <input
                  type="text"
                  id="name"
                  onChange={(e) => {
                    getData(e.target.value);
                  }}
                  placeholder="Search By Client Id"
                  // {...register("name", { required: "Name is required" })}
                  className="form-control"
                // className={`form-control ${errors.name ? "is-invalid" : ""}`}
                />
              </div>
            </form>
          </div>

          {/* table started */}
          <div className="table-main">
            <div className="table-responsive mt-2">
              <table className="table table-bordered  table-striped">
                <thead>
                  <tr>
                    <th className="name">
                      Client Name
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                     Client ID
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                     Pan
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Capital
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                                 
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((data, index) => (
                    <tr className="odd">
                      
                      <td>{data?.client_name}</td>
                      <td>{data?.client_id}</td>
                      <td>{data?.pan}</td>
                      <td>{data?.capital}</td>
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* <DataTableExtensions {...tableData}>
            <DataTable
              noHeader
              defaultSortAsc={true}
              // pagination
              highlightOnHover
            />
          </DataTableExtensions> */}
          {/* table ended */}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            TotalPages={TotalPages}
            TotalEntries={TotalEntries}
            perPage={perPage}
          />
        </div>
      </div>

      {/* {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}
      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      /> */}
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
