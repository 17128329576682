import React, { useContext, useState } from "react";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";

import Cookies from "js-cookie";
import { Context } from "../../../utils/context";
import Modallogout from "../../common/Modallogout";
library.add(fas);

function Header(props) {
  const { setUserData, setUsertype, setSignin, usertype } = useContext(Context);

  const navigate = useNavigate();

  const LogOut = async () => {
    setModalShow(false);
    Cookies.remove("profitking_security_testing", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <section className="header headerr">
        <Navbar expand="md" className="bg-body-tertiary">
          <Navbar.Brand>
            <div className="header-text">
              <div className="d-flex">
                <FontAwesomeIcon icon={props.icon} />
                <h1>{props.title}</h1>
              </div>

              {/* <p>Home</p> */}
              {/* <p>
                <FontAwesomeIcon className="users" icon="fa-solid fa-circle" />
                {props.title}
              </p> */}
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="header-icons">
                <div className="icon deskviewlogout">
                  <FontAwesomeIcon
                    className="users"
                    icon="fa-solid fa-power-off"
                    onClick={() => setModalShow(true)}
                  />
                </div>
                {/* onClick={LogOut} */}
              </div>
            </Nav>
          </Navbar.Collapse>

          <div className="icon mobileviewlogout" onClick={LogOut}>
            <FontAwesomeIcon className="users" icon="fa-solid fa-power-off" />
          </div>
        </Navbar>

        <Modallogout
          LogOut={LogOut}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </section>
    </>
  );
}

export default Header;
