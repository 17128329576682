import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { formatDate } from "../../../../utils/common";
import Pagination from "../../../common/Pagination";
import { getAllData } from "../../../../utils/apis/RateUs/RateUs";
import ExportMethod from "../../../../utils/apis/ExportMethod";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (data) => {};

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);

  const getData = async (search = "") => {
    const res = await getAllData(currentPage, perPage, search);
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
    },
    {
      name: "Feedback",
      selector: "feedback",
      sortable: true,
    },
    {
      name: "Rating",
      selector: "rating",
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data: data.data,
  };

  const handleExportCSV = () => {
    const header = [{ name: "Date" }, { name: "Feedback" }, { name: "Rating" }];
    const exportData = data?.data?.map((d) => [
      formatDate(d.createdAt),
      d.feedback,
      d.rating,
    ]);
    const { content, type, name } = ExportMethod.csv(
      exportData,
      header,
      "RateUs"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = () => {
    const header = [{ name: "Date" }, { name: "Feedback" }, { name: "Rating" }];
    const exportData = data?.data?.map((d) => [
      formatDate(d.createdAt),
      d.feedback,
      d.rating,
    ]);
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "RateUs"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handlePrint = () => {
    const header = [{ name: "Date" }, { name: "Feedback" }, { name: "Rating" }];
    const exportData = data?.data?.map((d) => [
      formatDate(d.createdAt),
      d.feedback,
      d.rating,
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Rate Us"} link={"/rate-us"} />
        <div className="search-investor mt-4">
          <div className="investor-main">
            <FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-3" />
            <p className="sub">Rate Us History</p>
          </div>
          <div className="inward-payment">
            <div className="nav-link active">
              <div className="dt-buttons btn-group flex-wrap">
                <button
                  className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handleExportExcel}
                >
                  <span>Excel</span>
                </button>
                <button
                  className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handleExportCSV}
                >
                  <span>CSV</span>
                </button>
                <button
                  className="btn btn-secondary buttons-print Print_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handlePrint}
                >
                  <span>Print</span>
                </button>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="simple-form outward-main"
            >
              <div className="form-group">
                <label htmlFor="name">Search: </label>
                <input
                  type="text"
                  onChange={(e) => {
                    getData(e.target.value);
                  }}
                  placeholder="Search By Feedback"
                  id="name"
                  className="form-control"
                />
              </div>
            </form>
          </div>
          {/* table started */}

          {/* <div className="main"> */}
          {/* <DataTableExtensions {...tableData}>
            <DataTable
              noHeader
              defaultSortAsc={true}
              // pagination
              highlightOnHover
            />
          </DataTableExtensions> */}
          {/* </div> */}
          <div className="table-main">
            <div className="table-responsive mt-2">
              <table className="table table-bordered  table-striped">
                <thead>
                  <tr>
                    <th className="name">
                      Date
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Feedback
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Rating
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((data, index) => (
                    <tr className="odd" key={index}>
                      <td>{formatDate(data?.createdAt)}</td>
                      <td>{data?.feedback}</td>
                      <td>{data?.rating}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* table ended */}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            TotalPages={TotalPages}
            TotalEntries={TotalEntries}
            perPage={perPage}
          />
        </div>
      </div>
    </>
  );
};

export default Tables;

// import React from "react";
// import ReactDOM from "react-dom";
// import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";

// const Tables = () => {
//   const columns = [
//     {
//       name: "Name",
//       selector: "name",
//       sortable: true,
//     },
//     {
//       name: "Position",
//       selector: "education.position",
//       sortable: true,
//     },
//     {
//       name: "Office",
//       selector: "office",
//       sortable: true,
//     },
//     {
//       name: "Age",
//       selector: "age",
//       sortable: true,
//     },
//     {
//       name: "Start date",
//       selector: "start_date",
//       sortable: true,
//     },
//     {
//       name: "Salary",
//       selector: "salary",
//       sortable: true,
//     },
//   ];

//   const data = [
//     {
//       name: "Tiger Nixon",
//       education: {
//         position: "System Architect",
//       },
//       office: "Edinburgh",
//       age: "61",
//       start_date: "2011/04/25",
//       salary: "$320,800",
//     },
//     {
//       name: "Garett Winters",
//       education: {
//         position: "Accountant",
//       },
//       office: "Tokyo",
//       age: "63",
//       start_date: "2011/07/25",
//       salary: "$170,750",
//     },
//   ];

//   const tableData = {
//     columns,
//     data,
//   };
//   return (
//     <div className="main">
//       <DataTableExtensions {...tableData}>
//         <DataTable
//           noHeader
//           defaultSortAsc={false}
//           // pagination
//           highlightOnHover
//         />
//       </DataTableExtensions>
//     </div>
//   );
// };

// export default Tables;
