import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Controller, useForm } from "react-hook-form";

import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBook, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-js-loader";
import { Button, Col, Row } from "react-bootstrap";
import Datepicker from "../../../common/Datepicker";
import { Link } from "react-router-dom";
import { Submit } from "../../../common/Button";
import {
  getAllDataPnlTransaction,
  CreatePnlTransaction,
  DeletePnlTransaction,
} from "../../../../utils/apis/PnL/PnL";
import Select from "react-select";
import { postData } from "../../../../utils/api";
import Pagination from "../../../common/Pagination";
import { formatDate, formatDateTime } from "../../../../utils/common";
import ExportMethod from "../../../../utils/apis/ExportMethod";
import DateTimePickerComponent from "../../../common/DateTimePicker";

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  const {
    reset,
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      type: { value: "Buy", name: "type", label: "Buy" },
      transaction_segment: "",
      buy_price: "",    
      sell_price: "",
      quantity: "",
      total: "",
      total_buy: "",
      total_sell: "",
      profit_loss: "",
      transaction_date:""
    },
  });
  const [firstSegment, setFirstSegment] = useState('');

  const onSubmit = async (data) => {
    console.log(data,"fdkhgjdfkghffkf");
    setShimmerLoader(true);
    const finalData = new FormData();

  
    finalData.append('transaction_segment', data?.transaction_segment);
    finalData.append('quantity', data?.quantity);
    finalData.append('buy_price', data?.buy_price);
    finalData.append('sell_price', data?.sell_price);
    finalData.append('transaction_date', data?.transaction_date);
    finalData.append('total', data?.total);
    finalData.append('total_buy', data?.total_buy);
    finalData.append('total_sell', data?.total_sell);
    finalData.append('profit_loss', data?.profit_loss);
  
    const res = await CreatePnlTransaction(finalData);
    reset();
   
    setImagePreview("");
    if (res?.success) {
      setFirstSegment(" ")
      setShimmerLoader(false);
      getData();
    }else{
      setShimmerLoader(false);
    }
    
  };

  const SegamentDelete = async (id) => {
    const res = await DeletePnlTransaction(id);
    getData();
  };

  const [type, setType] = useState([
    {
      value: "Buy",
      name: "type",
      label: "Buy",
    },
    {
      value: "Sell",
      name: "type",
      label: "Sell",
    },
  ]);

  const [data, setData] = useState([]);
  const [shimmerLoader, setShimmerLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);
  const { IMG_URL } = useContext(Context);

  const getData = async (search = "") => {
    const res = await getAllDataPnlTransaction(currentPage, perPage, search);
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  console.log(getValues(),"getValues() getValues()");
  const Calculate = async () => {
    const data = getValues();
    // console.log(data);
    if (!data.quantity) {
      setError(`quantity`, {
        type: "manual",
        message: "Enter quantity",
      });
    } else if (!data.buy_price) {
      setError(`buy_price`, {
        type: "manual",
        message: "Enter b_price",
      });
    } else if (!data.sell_price) {
      setError(`sell_price`, {
        type: "manual",
        message: "Enter s_price",
      });
    } else {
      clearErrors();
      // console.log(data);
      const total_buy = data.buy_price * data.quantity;
      // console.log("total_buy =" + total_buy);
      const total_sell = data.sell_price * data.quantity;
      // console.log("total_sell =" + total_sell);
      const profit_loss = total_sell - total_buy;
      // console.log("profit_loss =" + profit_loss);
      setValue("total_buy", total_buy);
      setValue("total_sell", total_sell);
      setValue("total", profit_loss);
    }
  };

  // console.log(errors);

  //

  const handleExportCSV = () => {
    const header = [
      { name: "Transaction Date" },
      { name: "Transaction Segment" },
      { name: "Buy Price" },   
      { name: "Sell Price" },
      { name: "Quantity" },
      { name: "Net Pnl" },
    ];
    const exportData = data?.data?.map((data) => [
      data?.transaction_date,
      data?.transaction_segment,
      data?.buy_price,
      data?.sell_price,
      data?.quantity,
      data?.net_pnl,  
    ]);
    const { content, type, name } = ExportMethod.csv(
      exportData,
      header,
      "Pnl Transaction"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = () => {
    const header = [
      { name: "Transaction Date" },
      { name: "Transaction Segment" },
      { name: "Buy Price" },   
      { name: "Sell Price" },
      { name: "Quantity" },
      { name: "Net Pnl" },
    
    ];
    const exportData = data?.data?.map((data) => [
      data?.transaction_date,
      data?.transaction_segment,
      data?.buy_price,
      data?.sell_price,
      data?.quantity,
      data?.net_pnl,     
    ]);
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "Pnl Transaction"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handlePrint = () => {
    const header = [
      { name: "Transaction Date" },
      { name: "Transaction Segment" },
      { name: "Buy Price" },   
      { name: "Sell Price" },
      { name: "Quantity" },
      { name: "Net Pnl" },
    ];
    const exportData = data?.data?.map((data) => [
      data?.transaction_date,
      data?.transaction_segment,
      data?.buy_price,
      data?.sell_price,
      data?.quantity,
      data?.net_pnl,  
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  };

  const [imagePreview, setImagePreview] = useState(null); // State to store image preview

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview
      };
      reader.readAsDataURL(file);
    }
  };

  console.log(getValues("transaction_segment"),"hhhhhhhhhhhhhhhhhhhhhhh");
  return (
    <>
      <div className="main-advancedashboard">
        <div className="investor">
          <Header title={"Pnl Transaction"} link={"/payment/inward-payment"} />

          <div className="search-investor">
            <ul>
            <li className="list-opera">
                <div className="operation">
                  <p className="op">
                  Transaction Date
                  </p>

                  <div className="form-group me-3">
                  <input
                      type="date"
                      id="transaction_date"
                      placeholder="transaction_date"
                      {...register("transaction_date", {
                        required: "transaction_date is required",
                      })}
                      className={`form-control ${errors.transaction_date ? "is-invalid" : ""
                        }`}
                    />
                    </div>

                </div>
              </li>

             
              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    Add Transaction Segment <span className={"buy"}>Buy</span>
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="text"
                      id="name"
                      placeholder="Segment Name"
                      {...register("transaction_segment", {
                        required: "Segment name is required",
                        onChange: (e) => setFirstSegment(e.target.value)
                      })}
                      className={`form-control ${errors.transaction_segment ? "is-invalid" : ""
                        }`}
                    />
                    {/* {errors.name && (
                      <div className="invalid-feedback">
                        {errors.name.message}
                      </div>
                    )} */}
                  </div>
                  <div className="form-group me-2">
                    <input
                      type="number"
                      id="name"
                      placeholder="Price"
                      {...register("buy_price", {
                        required: "Price is required",
                      })}
                      className={`form-control ${errors.buy_price ? "is-invalid" : ""
                        }`}
                    />
                  </div>
                  {/* <div className="form-group me-2">
                    <input
                      type="text"
                      id="name"
                      placeholder="Buying Time hh:mm:ss"
                      {...register("b_date", {
                        required: "Buying Time is required",
                      })}
                      className={`form-control ${errors.b_date ? "is-invalid" : ""
                        }`}
                    />
                  </div> */}

                  {/* <DateTimePickerComponent
                    setValue={setValue}
                    name={"b_date"}
                    errors={errors}
                    register={register}
                  /> */}
                </div>
              </li>

              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    Add Transaction Segment <span className={"sell"}>Sell</span>
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="text"
                      id="name"
                      placeholder="Segment Name"
                      {...register("transaction_segment1", {
                     
                      })}
                      className={`form-control ${errors.buy_price ? "is-invalid" : ""
                      }`}
                     
                       
                        value={firstSegment}
                        disabled
                    />
                  </div>
                  <div className="form-group me-2">
                    <input
                      type="number"
                      id="name"
                      placeholder="Price"
                      {...register("sell_price", {
                        required: "Price is required",
                      })}
                      className={`form-control ${errors.sell_price ? "is-invalid" : ""
                        }`}
                    />
                  </div>

                  {/* <div className="form-group me-2">
                    <input
                      type="text"
                      id="name"
                      placeholder="Selling Time hh:mm:ss"
                      {...register("s_date", {
                        required: "Selling Time is required",
                      })}
                      className={`form-control ${errors.s_date ? "is-invalid" : ""
                        }`}
                    />
                  </div> */}

                  {/* <DateTimePickerComponent
                    setValue={setValue}
                    name={"s_date"}
                    errors={errors}
                    register={register}
                  /> */}
                </div>
              </li>

              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    Add Transaction Segment <span className="textcolor-holder">Qty</span>
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="number"
                      placeholder="quantity"
                      {...register("quantity", {
                        required: "quantity is required",
                      })}
                      className={`form-control ${errors.quantity ? "is-invalid" : ""
                        }`}
                    />
                  </div>
                </div>
              </li>
              <li className="list-opera">
                <div className="operation">
                  <p className="op">
                    P&L <span className="textcolor-holder">Updation</span>
                  </p>

                  <div className="form-group me-3">
                    <input
                      type="number"
                      placeholder="P&L amount"
                      disabled
                      {...register("total", {
                        required: "P&L amount is required",
                      })}
                      className={`form-control ${errors.total ? "is-invalid" : ""
                        }`}
                    />
                  </div>
                </div>
              </li>

              <li className="list-opera list-Submit-btn ">
                <div className="operation">
                  <p className="op"></p>

                  <div className="Submit  Submit-btn-holder">
                    <button className="btn btn-warning" onClick={Calculate}>
                      Calculate
                    </button>
                  </div>

                  {/* <div className="Submit  Submit-btn-holder">
                    <button className="btn btn-warning" onClick={() => reset()}>
                      Reset
                    </button>
                  </div> */}
                  <div className="Submit  Submit-btn-holder">
                    {shimmerLoader ? (
                     <Loader type="bubble-top" bgColor={"#000"} color={"#000"} size={50} />
                    ) : (
                      <Submit name="hjgh" handleSubmit={handleSubmit(onSubmit)} />
                    )}



                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="search-investor mt-4">
            <div className="investor-main">
              <FontAwesomeIcon className="me-3" icon={faBook} />
              <p className="sub">Segment History</p>
            </div>
            <div className="inward-payment">
              <div className="nav-link active">
                <div className="dt-buttons btn-group flex-wrap">
                  {/* <button
                    className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportExcel}
                  >
                    <span>Excel</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportCSV}
                  >
                    <span>CSV</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-print Print_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handlePrint}
                  >
                    <span>Print</span>
                  </button> */}
                </div>
              </div>
              <div className="simple-form outward-main">
                <div className="form-group">
                  {/* <label htmlFor="name">Search: </label>
                  <input
                    type="text"
                    id="name"
                    onChange={(e) => {
                      getData(e.target.value);
                    }}
                    className={`form-control ${errors.name ? "is-invalid" : ""
                      }`}
                  /> */}
                  {errors.name && (
                    <div className="invalid-feedback">
                      {errors.name.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* table started */}
            <div className="table-main">
              <div className="table-responsive mt-2">
                <table className="table table-bordered  table-striped">
                  <thead>
                    <tr>
                      <th className="name">
                        Segment ID
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                     
                      <th className="name">
                        Transaction Segment Name
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Buy Price
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                      Sell Price
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                       Quantity
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Net PNL
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                     
                     
                      {/* <th className="name">
                        Delete Segment
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th> */}

                      <th className="name">
                        Date
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((data, index) => (
                      <tr className="odd">
                        <td>{data?.id}</td>
                      
                        <td>{data?.transaction_segment}</td>
                        <td>{data?.buy_price}</td>
                        <td>{data?.sell_price}</td>
                        <td>{data?.quantity}</td>
                        <td>{data?.net_pnl}</td>
                       
                       
                        {/* <td>
                          <button
                            type="button"
                            onClick={() => SegamentDelete(data.id)}
                            className="btn btn-danger"
                          >
                            Delete
                          </button>{" "}
                        </td> */}
                        <td>{data?.transaction_date}</td>

                        {/* <td
                        valign="top"
                        colspan="10"
                        className="dataTables_empty text-center"
                      >
                        No data available in table
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* table ended */}

            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              TotalPages={TotalPages}
              TotalEntries={TotalEntries}
              perPage={perPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
