import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Country = async () => {
  try {
    return await getData(`/without-login/masters/all-country`);
  } catch (error) {
    console.error(error);
  }
};

export const State = async () => {
  try {
    return await getData(`/without-login/masters/all-states`);
  } catch (error) {
    console.error(error);
  }
};

export const Occupation = async () => {
  try {
    return await getData(`/without-login/masters/all-occupation`);
  } catch (error) {
    console.error(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/without-login/masters/all-city/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Pincode = async (name) => {
  try {
    return await getData(`/without-login/masters/all-pincode/${name}`);
  } catch (error) {
    console.error(error);
  }
};

export const allPincode = async () => {
  try {
    return await getData(`/without-login/masters/all-pincode`);
  } catch (error) {
    console.error(error);
  }
};

export const getSetup = async () => {
  try {
    return await getData(`/masters/app-setup/single`);
  } catch (error) {
    console.error(error);
  }
};

