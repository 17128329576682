import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (
  state,
  city,
  occupation,
  service_number,
  investor_name,
  from,
  to,
  bank_status,
  kyc_status,
  page,
  per_page,
  name,
) => {
  try {
    let url = `/investors/investors?`;

    if (state && state.value) {
      url += `&state=${state?.value}`;
    }
    if (city && city.value) {
      url += `&city=${city?.value}`;
    }

    if (occupation && occupation.value) {
      url += `&occupation=${occupation?.value}`;
    }

    if (service_number) {
      url += `&service_number=${service_number}`;
    }

    if (investor_name) {
      url += `&investor_name=${investor_name}`;
    }

    if (name) {
      url += `&name=${name}`;
    }

    if (from) {
      url += `&from=${from}`;
    }

    if (to) {
      url += `&to=${to}`;
    }

    if (kyc_status?.value) {
      url += `&kyc_status=${kyc_status?.value}`;
    }

    if (bank_status?.value) {
      url += `&bank_status=${bank_status?.value}`;
    }

    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const kycStatus = async (data) => {
  try {
    return await postData(`/investors/investors/kycDetailStatus`,data);
  } catch (error) {
    console.error(error);
  }
};


export const InwardManual = async (data) => {
  try {
    return await postData(`/investors/investors/manual/payment`, data);
  } catch (error) {
    console.error(error);
  }
};
