import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ScreenShot = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="sm"
        className="Payment_Screenshotmodal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Screenshot
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Payment_Screenshot_img_holder">
            <img
              className="Payment_Screenshot_img"
              src={props.show}
              alt="Payment_Screenshot_img"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScreenShot;
