import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { InwardAccepted, InwardEdit } from "../../utils/apis/Inward/Inward";

const ScreenShot = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    reset,
  } = useForm();

  const EditAmount = async (id) => {
    const amount = getValues(`amount`);

    const data = {
      previous_amount: props.previousAmount,
      amount: amount,
      id: props.inwardID,
    };
    if (amount > 0) {
      const res = await InwardEdit(data);
      if (res.success) {
        props.getData();
        props.onHide();
      }
      // getData();
      // Proceed with your logic here
    } else {
      await setError(`amount`, {
        type: "manual",
        message: "Enter amount",
      });
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="sm"
        className="Payment_Screenshotmodal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Amount Edit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <sub>
              <b>Previous Amount:</b> ₹ {props.previousAmount}
            </sub>
            <input
              type="number"
              placeholder="Amount"
              {...register(`amount`, {})}
              className={`form-control mt-3 ${
                errors[`amount`] ? "is-invalid" : ""
              }`}
            />

            <button
              type="button"
              className="btn btn-success mt-3"
              onClick={() => EditAmount()}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScreenShot;
